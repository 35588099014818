import { IconButton, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetchApiData, updateApiData } from '../../Components/Api'
import EnhancedTable from '../../Components/EnhancedTable'
import HeaderName from '../../Components/HeaderName'

import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';


function createData(id, name, leaveFrom, leaveTo, description, leaveType, actions) {
    return {
        id,
        name, 
        leaveFrom, 
        leaveTo, 
        description, 
        leaveType,
        actions
    };
  }

const Leave = () => {
    const [loader,setLoader] = useOutletContext();
  const [rows, setRows] = useState([])
  
  const headCells = [
    {
        id: 'id',
        numeric: false,
        disablePadding: false,
        label: 'Id',
      },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'leaveFrom',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Leave From',
    },
    {
      id: 'leaveTo',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Leave To',
    },
    {
      id: 'description',
      numeric: true,
      disablePadding: false,
      label: 'Description',
    },
    {
        id: 'leaveType',
        numeric: true,
        disablePadding: false,
        label: 'Leave Type',
    },
    {
        id: 'actions',
        type: 'custom',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },
  ];

  const acceptLeave = (id) => {
    updateApiData('records/leave/' ,id ,{"status": "accepted"},localStorage.getItem('session')).then()
    fetchData()
  }

  const rejectLeave = (id) => {
    updateApiData('records/leave/' ,id ,{"status": "rejected"},localStorage.getItem('session')).then()
    fetchData()
  }

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/getLeaves',localStorage.getItem('session')).then(resp => {
        setRows([])
        resp.data.data.map(data => {
            setRows(prev => [...prev , createData(data.id,data.user.firstName+' '+data.user.lastName,data.leaveFrom,data.leaveTo,data.description,data.leaveType, data.status === 'pending'? (
                <>
                  <IconButton variant="text"  onClick={() => {
                    acceptLeave(data.id)
                  }}><DoneIcon/></IconButton>
                  <IconButton variant="text"  onClick={() => {
                    rejectLeave(data.id)
                }}><BlockIcon/></IconButton>  
                </>
            ): (data.status))])
        })
        console.log(resp)
    }).finally(() => setLoader(false))
  }

  useEffect(() => {
    fetchData()
  },[])
  return (
    <>
  <HeaderName/>
    <div style={{display: "flex", justifyContent:'space-between'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Leave
        </Typography>
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
    </>
  )
}

export default Leave