import { Button, FormControl, MenuItem, Modal, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver';

import DeleteIcon from '@mui/icons-material/Delete';
import ReactSignatureCanvas from 'react-signature-canvas';
import AddProductModal from './AddProductModal';
import axios from 'axios';
import { createApiData } from '../../Components/Api';
import Base64toBlob from '../../Components/Base64toBlob';
import { useNavigate, useOutletContext } from 'react-router-dom';

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const AddCvdModal = ({setCvdFormData,cvdFormData,cvdModal,toggleCvdModal,fetchData}) => {
  const [loader, setLoader] = useOutletContext();
  const navigate = useNavigate();
    const [expanded, setExpanded] = useState(0);
    const [addProductModal,setAddProductModal] = useState(false)
    var signRef = {};

    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        border: '1px solid black',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

      useEffect(() => {
          if (typeof signRef.fromDataURL === 'function') {
              // do something
              signRef?.fromDataURL(cvdFormData['signature'])
            }
        },[expanded])
        
        const handleChange =
        (event, newValue) => {
            setExpanded(newValue);
        };

        const handleCvdSubmit = (event) => {
          let cvdSignature = cvdFormData['signature']
          let cvdPeriod = cvdFormData['period']
            event.preventDefault();
            console.log(event.target,cvdFormData)
            cvdFormData['signature'] = cvdFormData['signature'].split(',')[1]
            cvdFormData['period'] = dayjs(cvdFormData['period']).format('DD-MM-YYYY')
            axios.post('https://goasoftwarecompany.com/safemeat/public/api/declaration', cvdFormData, {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }).then(async data => {
              if(data.data.code == "200"){
                Base64toBlob(data.data.data,'cvdfile').then(file => {
                  let formData = new FormData();
                  formData.append("buyerName",cvdFormData["buyerName"])
                  formData.append("transporter",cvdFormData['driverName'])
                  formData.append("pic",cvdFormData['buyerPIC'])
                  formData.append("cvdName",dayjs(new Date()).format('DD-MM-YYYY')+" "+cvdFormData['organisationName'])
                  formData.append("photo",file)
                      createApiData('users/createCvd',formData,localStorage.getItem('session')).then(data => {
                        console.log(data)
                        if(data.status === 200) {
                          alert("CVD created successfully")
                            toggleCvdModal()
                            fetchData()
                            navigate('/')
                        }
                      })

                })

              }else{
                // for(let i = 0; i < dat)
                // data.data.data
                cvdFormData['signature'] = cvdSignature
                cvdFormData['period'] = dayjs(cvdFormData['period'])
                console.log(data.data.data)
                alert(data.data.data[Object.keys(data.data.data)[0]])
              }
            })
        
          }

        
    
    const toggleAddProductModal = () => {
        setAddProductModal(prev => !prev)
    }
    
    const addProductModalProps={
        addProductModal : addProductModal,
        toggleAddProductModal: toggleAddProductModal,
        setCvdFormData: setCvdFormData,
        cvdFormData: cvdFormData
    }
    return (
        <>

    <Modal
        open={cvdModal}
        onClose={toggleCvdModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >

        <Box sx={{ ...modalStyle, width: 500, borderRadius: 5 }}>
          <form onSubmit={handleCvdSubmit}>
            <h2 id="child-modal-title">CVD Form</h2>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={expanded}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Vendor Details" {...a11yProps(0)} />
                <Tab label="Buyer Details" {...a11yProps(1)} />
                <Tab label="Transporter" {...a11yProps(2)} />
                <Tab label="Commodity Details" {...a11yProps(3)} />
                <Tab label="Product Integrity" {...a11yProps(4)} />
                <Tab label="Chemical Use" {...a11yProps(5)} />
                <Tab label="Self Declaration" {...a11yProps(6)} />
              </Tabs>
            </Box>
            <Box sx={{ height: 400, marginBottom: 2, overflow: 'scroll', overflowX: 'hidden' }}>

              <TabPanel value={expanded} index={0} style={{ width: '110%', marginLeft: '-20px' }}>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Name'
                  name='vd-name'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorName: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorName']}
                  required
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Address'
                  name='vd-address'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorAddress: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorAddress']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Town'
                  name='vd-town'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorTown: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorTown']}
                  required
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Mobile'
                  inputProps={{ maxLength: 9 }}
                  name='vd-mobile'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorContact: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorContact']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Fax'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorFax: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorFax']}
                  name='vd-fax'
                />


                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Email ID'
                  name='vd-email'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorEmail: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorEmail']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='National Grower Registration (NGR) No'
                  name='vd-ngr'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorNGR: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorNGR']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  inputProps={{ style: { textTransform: "uppercase" },
                    maxLength: 8 }}
                  label='Property Identification Code (PIC)'
                  name='vd-pic'
                  error = {(cvdFormData['vendorPIC'].length !== 8 && true)}
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorPIC: e.target.value.toUpperCase() })
                  }}
                  defaultValue={cvdFormData['vendorPIC']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Vendor contract/reference number'
                  name='vd-vcr'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorRefrence: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorRefrence']}
                />
              </TabPanel>
              <TabPanel value={expanded} index={1} style={{ width: '110%', marginLeft: '-20px' }}>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Name'
                  name='bd-name'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerName: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerName']}
                  required
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Address'
                  name='bd-address'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerAddress: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerAddress']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Town'
                  name='bd-town'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerTown: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerTown']}
                  required
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  inputProps={{ maxLength: 9 }}
                  label='Mobile'
                  name='bd-mobile'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerContact: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerContact']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Fax'
                  name='bd-fax'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerFax: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerFax']}
                />


                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Email ID'
                  name='bd-email'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerEmail: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerEmail']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='National Grower Registration (NGR) No'
                  name='bd-ngr'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerNGR: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerNGR']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  inputProps={{ 
                    style: { textTransform: "uppercase" },
                    maxLength: 8 }}
                  label='Property Identification Code (PIC)'
                  error = {(cvdFormData['buyerPIC'].length !== 8 && true)}
                  name='bd-pic'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerPIC: e.target.value.toUpperCase() })
                  }}
                  defaultValue={cvdFormData['buyerPIC']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Buyers Contract No.'
                  name='bd-bcn'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, buyerRefrence: e.target.value })
                  }}
                  defaultValue={cvdFormData['buyerRefrence']}
                />
              </TabPanel>
              <TabPanel value={expanded} index={2} style={{ width: '110%', marginLeft: '-20px' }}>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Driver Name'
                  name='tr-driverName'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, driverName: e.target.value })
                  }}
                  defaultValue={cvdFormData['driverName']}
                />


                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Email ID'
                  name='tr-email'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, driverEmail: e.target.value })
                  }}
                  defaultValue={cvdFormData['driverEmail']}
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  inputProps={{ maxLength: 9 }}
                  label='Mobile'
                  name='tr-mobile'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, driverContact: e.target.value })
                  }}
                  defaultValue={cvdFormData['driverContact']}
                />



                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Company'
                  name='tr-company'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, companyName: e.target.value })
                  }}
                  defaultValue={cvdFormData['companyName']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Registration'
                  name='tr-registeration'
                  inputProps={{ 
                    style: { textTransform: "uppercase" } }}
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, registration: e.target.value.toUpperCase() })
                  }}
                  defaultValue={cvdFormData['registration']}
                />
              </TabPanel>
              <TabPanel value={expanded} index={3} style={{ width: '110%', marginLeft: '-20px' }}>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Commodity'
                  name='cd-commodity'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, commodity: e.target.value })
                  }}
                  defaultValue={cvdFormData['commodity']}
                />
                
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Commodity2'
                  name='cd-commodity2'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, commodity: e.target.value })
                  }}
                  defaultValue={cvdFormData['commodity']}
                />

                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Variety 1'
                  name='cd-variety1'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, variety1: e.target.value })
                  }}
                  defaultValue={cvdFormData['variety1']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Variety 2'
                  name='cd-variety2'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, variety2: e.target.value })
                  }}
                  defaultValue={cvdFormData['variety2']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Quantity 1'
                  name='cd-quantity1'
                  type='number'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, quantity1: e.target.value })
                  }}
                  defaultValue={cvdFormData['quantity1']}
                  required
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Quantity 2'
                  type='number'
                  name='cd-quantity2'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, quantity2: e.target.value })
                  }}
                  defaultValue={cvdFormData['quantity2']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Unit'
                  name='cd-unit1'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, cdUnit1: e.target.value })
                  }}
                  defaultValue={cvdFormData['cd-unit2']}
                />
                
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Unit'
                  name='cd-unit2'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, cdUnit2: e.target.value })
                  }}
                  defaultValue={cvdFormData['cdUnit2']}
                />
                
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Total Quantity'
                  name='cd-totalQuantity'
                  value={parseInt(cvdFormData['quantity1'] !== '' ? cvdFormData['quantity1'] : 0)+parseInt(cvdFormData['quantity2'] !== '' ? cvdFormData['quantity2'] : 0)}
                  InputLabelProps={{
            shrink: true,
          }}

                />
              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DateField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Delivery'
                  format='DD/MM/YYYY'
                  name='cd-deliveryPeriod'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, period: e.target.value })
                  }}
                  
                  defaultValue={cvdFormData['period']}
                />
              </LocalizationProvider>


              </TabPanel>
              <TabPanel value={expanded} index={4} style={{ width: '110%', marginLeft: '-20px' }}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>Part A - Product Integrity </Typography>

                <Typography>1. Commodity Source (select one)</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, sourceCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['sourceCheck'] === "" ? "Select an Option" : cvdFormData['sourceCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="0">Single Source, Single Storage (eg off the header)</MenuItem>
                    <MenuItem value="1">Single Source, Comingled Storage (eg farm silo)</MenuItem>
                    <MenuItem value="2">Multi-Vendor Storage (eg grain depot, cotton gin seed storage)</MenuItem>
                    <MenuItem value="3">Factory Developed Product (eg ethanol plant, gluten plant)</MenuItem>
                  </Select>
                </FormControl>
                <Typography>2. Does this commodity contain restricted animal materials ( eg meat and bone meal)</Typography>

                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, materialCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['materialCheck'] === "" ? "Select an Option" : cvdFormData['materialCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="0">Yes</MenuItem>
                    <MenuItem value="1">No</MenuItem>
                  </Select>
                </FormControl>
                <Typography>3. With respect to Genetically Modified Organisms. this commodity: (tick one)</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, gmoCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['gmoCheck'] === "" ? "Select an Option" : cvdFormData['gmoCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="0">Is non GMO as defined by 99% non GMO</MenuItem>
                    <MenuItem value="1">Is non GMO as defined by 95% non GMO</MenuItem>
                    <MenuItem value="2">Contains greater than 5% GMO or content unknown</MenuItem>
                  </Select>
                </FormControl>
              </TabPanel>
              <TabPanel value={expanded} index={5} style={{ width: '110%', marginLeft: '-20px' }}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>Part B - Chemical Use </Typography>

                <Typography>4. Is this commodity within a withholding period (WHP), Export Slaughter Interval (ESI) or Export Animal Feed Interval (EAFI) following treatment with any plant chemical including a pickling or seed treatment, fumigant, pesticide or insecticide?</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, chemicalCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['chemicalCheck'] === "" ? "Select an Option" : cvdFormData['chemicalCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
                <TableContainer component={Paper} sx={cvdFormData['chemicalCheck'] == 1 ? {display: 'block'} : {display: 'none'}}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Chemical Applied</TableCell>
            <TableCell align="right">Rate</TableCell>
            <TableCell align="right">Application Date</TableCell>
            <TableCell align="right">WHP / ESI / ESFI</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cvdFormData.chemicals.map((row,index) => (
            <TableRow
              key={row.chemicalName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.chemicalName}
              </TableCell>
              <TableCell align="right">{row.rate}</TableCell>
              <TableCell align="right">{row.applicationDate}</TableCell>
              <TableCell align="right">{row.WHP}</TableCell>
              <TableCell align="right">
                <Button 
                  onClick={() => setCvdFormData({...cvdFormData, chemicals: cvdFormData.chemicals.filter((a,i) => i !== index)})}
                  ><DeleteIcon/></Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button
    hidden={cvdFormData['chemicalCheck'] == 1 ? false : true}
     onClick={() => toggleAddProductModal()} variant='contained'>Add Another Product</Button>
                <Typography>5. Has the commodity been grown and/or stored under an independently audited QA program which includes chemical residue management?</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    
                    defaultValue={cvdFormData['qaCheck'] === "" ? "Select an Option" : cvdFormData['qaCheck']}
                    onChange={(e)=> setCvdFormData({ ...cvdFormData, qaCheck: e.target.value })}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
                {cvdFormData['qaCheck'] == 1 && <><TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='QA Program'
                  name='cu-qa-program'
                  onChange={(e)=> setCvdFormData({ ...cvdFormData, qaProgram: e.target.value })}
                  defaultValue={cvdFormData['qaProgram']}
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Accreditation/ Certification Number'
                  name='cd-accno'
                  onChange={(e)=> setCvdFormData({ ...cvdFormData, certificateNumber: e.target.value })}
                  
                    defaultValue={cvdFormData['certificateNumber'] !=="" ? cvdFormData['cerificateNumber']: ''}
                /></>}

                <Typography>6. Is the vendor of this commodity currently aware of its full chemical treatment history or holds a CVD containing this history?</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, cvdCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['cvdCheck'] === "" ? "Select an Option" : cvdFormData['cvdCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>

                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
                <Typography>7. List of all known adjacent crops grown within 100 metres of this commodity (only applicable for single soiurce commodities)</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  {cvdFormData.cropList.map((data, index) => <TextField
                   key={index}
                    sx={{ margin: 1, color: 'black' }}
                    id="outlined-required"
                    name='cu-7'
                    defaultValue={data.cropName}
                    onChange={(e)=> setCvdFormData({...cvdFormData,cropList: cvdFormData.cropList.map((item,i) => 
                    i === index 
                    ? {...item, cropName : e.target.value}
                    : item )})}
                    require
                    InputProps={{
                      endAdornment: (
                        <Button onClick={() => {
                          setCvdFormData({...cvdFormData,cropList: cvdFormData.cropList.filter((d,i) => i !== index)})
                         
                        }}>-</Button>
                      ),
                    }}
                  />)
                  }
                  <Button onClick={() => {
                    setCvdFormData(prev => ({...prev,cropList: [...prev.cropList,{cropName: ''}]}))
                    console.log(cvdFormData.cropList)
                  }}>+</Button>
                </FormControl>
                <Typography>8. If the commodity is a by-product , has a risk assessment been completed? (tick one)</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, riskCheck: e.target.value })  
                    }}
                    defaultValue={cvdFormData['riskCheck'] === "" ? "Select an Option" : cvdFormData['riskCheck']}
                    required
                  >
                    <MenuItem value="Select an Option" disabled>Select an Option</MenuItem>

                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                    <MenuItem value="2">N/A</MenuItem>
                  </Select>
                </FormControl>
                {cvdFormData['riskCheck'] == 1 && <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Risk Assesment Results'
                  name='cu-risk-assesment-results'
                  onChange={(e)=> setCvdFormData({ ...cvdFormData, riskAssessmentResult: e.target.value })}
                  defaultValue={cvdFormData['riskAssessmentResult']}
                />}
                <Typography>9. Has the commodity been analysed for chemical residue or toxins by a lab accredited by NATA for the specific test required?</Typography>
                <FormControl fullWidth style={{ marginTop: 10, marginBottom: 10 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    onChange={(e) => {
                      setCvdFormData({ ...cvdFormData, nataCheck: e.target.value })
                    }}
                    defaultValue={cvdFormData['nataCheck'] === "" ? "Select an Option" : cvdFormData['nataCheck']}
                    required
                  >

                    <MenuItem value="Select an Option" defaultChecked disabled>Select an Option</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                </FormControl>
                {cvdFormData['nataCheck'] == 1 && <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Test Results'
                  name='cu-test-results'
                  onChange={(e)=> setCvdFormData({ ...cvdFormData, testResult: e.target.value })}
                  defaultValue={cvdFormData['testResult']}
                />}
              </TabPanel>
              <TabPanel value={expanded}  index={6} style={{ width: '110%', marginLeft: '-20px' }}>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Name'
                  name='sd-name'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, vendorName: e.target.value })
                  }}
                  defaultValue={cvdFormData['vendorName']}
                  fullWidth
                />
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Organization'
                  name='sd-organization'
                  onChange={(e) => {
                    setCvdFormData({ ...cvdFormData, organisationName: e.target.value })
                  }}
                  defaultValue={cvdFormData['organisationName']}
                  fullWidth
                />
                <Typography color="red"> Please read below points carefully</Typography>
                <Typography>I Andrew Davidson of ITRAK declare that:</Typography>
                <Typography>1. I am the duly authorised representative of the vendor supplying the commodity</Typography>
                <Typography>2. All the information in this document is true and correct</Typography>
                <Typography>3. I have read, understood and answered all questions in accordance with the explanatory notes</Typography>
                <Typography>4. I understand that regulatory authorities may take legal action, and purchasers may seek damages if the information provided is fale or misleading</Typography>
                <div>
                <Typography variant='h6'>Signature: </Typography>

                <ReactSignatureCanvas 
                  penColor='black'
                  ref={(ref) => { signRef = ref }}
                  onEnd={(e) => {
                    setCvdFormData({ ...cvdFormData, signature: signRef.toDataURL() })
                  }}
                  canvasProps={
                    {width: 450, 
                    height: 200, 
                    className: 'sigCanvas',
                    style: {
                      border: '4px solid green',
                      borderRadius:'10px'
                    }
                    }} />
                </div>
                {/* <Button onClick={() => console.log(signRef.toDataURL())} variant='contained' fullWidth>Signature</Button> */}
                <Button onClick={() => {
                  signRef.clear()
                  setCvdFormData({ ...cvdFormData, signature: '' })

                  }} variant='contained' >Clear Signature</Button>
              </TabPanel>


            </Box>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {expanded > 0 && <Button variant="contained" onClick={() => setExpanded(prev => prev - 1)} sx={{ marginLeft: 2 }}>Back</Button>}
              <Button variant="outlined" onClick={toggleCvdModal} sx={{ marginLeft: 2 }}>Close</Button>

              {expanded < 6 && <Button variant="contained" onClick={() => setExpanded(prev => prev + 1)} sx={{ marginLeft: 2 }}>Next</Button>}
              {expanded === 6 && <Button type='submit' variant="contained" sx={{ marginLeft: 2 }}>Submit</Button>}

            </div>
          </form>
        </Box>
      </Modal>

      <AddProductModal {...addProductModalProps}/>
      </>
  )
}

export default AddCvdModal