const formatAmount = (str) => {
    var splitString = str.toString().split("");
  
    var reverseArray = splitString.reverse();
    var formatArray = []
    var count = 0
    for(let i = 0; i< reverseArray.length; i++){
      count ++
      formatArray.push(reverseArray[i])
      if(count == 3 && reverseArray[i+1]!== undefined){
        formatArray.push(',')
        count = 0
      }
    }
  
    var joinArray = formatArray.reverse()
    joinArray = joinArray.join("")
    
    return joinArray;
  }

  export default formatAmount