import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import { Button, Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { fetchApiData } from '../../Components/Api';
import { useOutletContext } from 'react-router-dom';
import { useExcelDownloder } from '@coinsummer/react-xls';


function createData(zone,companyOwner,fullName,notificationDate) {
  return {
    zone,
    companyOwner,
    fullName,
    notificationDate
  };
}


const Notifications = () => {
  const [loader,setLoader] = useOutletContext();
  const [rows, setRows] = useState([])
  const [excelData, setExcelData] = useState({
    bioplusNotification: [
    ],
  })

  const {ExcelDownloder, Type} = useExcelDownloder() 
  const headCells = [
    {
      id: 'zone',
      numeric: true,
      disablePadding: false,
      label: 'Zone',
    },
    {
      id: 'companyOwner',
      numeric: true,
      disablePadding: false,
      label: 'Company Owner',
    },
    {
      id: 'fullName',
      numeric: false,
      disablePadding: false,
      label: 'Reciever',
    },
    {
      id: 'notificationDate',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Notification Time',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/record/notification',localStorage.getItem('session')).then(data => {
      console.log(data)
      setRows([])

      
      data.data.data.map(data => {
        
        console.log(data)
        setRows(prev => prev = ([...prev, createData(data.geofence.name,data.geofence.companyOwner,data.user.length > 0 ? data.user.join(',') : 'Empty',data.notificationDate
        )]))
        setExcelData(prev => prev = ({...prev, bioplusNotification: [...prev.bioplusNotification, {"Zone":data.geofence.name,"Company Owner": data.geofence.companyOwner,"Reciever": data.user.length > 0 ? data.user.join(',') : 'Empty',"Notification Date":data.notificationDate}]}))
        return ''
      })
    }).finally(() => setLoader(false))
  }

  useEffect(()=> {
    fetchData()
  },[])

  return (
    <>
  <HeaderName/>
    <div style={{display: "flex", justifyContent: "space-between"}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Notifications
        </Typography>
    {excelData.bioplusNotification != '' &&
        <ExcelDownloder
        data={excelData}
        filename={'Bioplus Notification'}
        type={Type.Link} // or type={'link'}
        >
        <Button variant='outlined' style={{marginRight: 20}}>Download XLS</Button>
      </ExcelDownloder>
        }
        
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
  </>
  )
}

export default Notifications