import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { createApiData, fetchApiData } from '../../Components/Api';
import EnhancedTable from '../../Components/EnhancedTable';
import HeaderName from '../../Components/HeaderName';
import Constants from '../../Constants';

function createData(
  consignment,
  created,
  fromPic,
  toPic,
  species,
  quantity,
  accreditation,
  transporter,
  mobile,
  status,
  pdf
) {
  return {
    consignment,
    created,
    fromPic,
    toPic,
    species,
    quantity,
    accreditation,
    transporter,
    mobile,
    status,
    pdf,
  };
}

const Envd = () => {
  const [loader, setLoader] = useOutletContext();
  const [token, setToken] = useState();
  const [rows, setRows] = useState([]);
  const [picList, setPicList] = useState([]);
  const [activePic, setActivePic] = useState();
  const headCells = [
    {
      id: 'consignment',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Consignment',
    },
    {
      id: 'created',
      numeric: false,
      type: 'date',
      disablePadding: false,
      label: 'Created',
    },
    {
      id: 'fromPic',
      numeric: false,
      disablePadding: false,
      label: 'From PIC',
    },
    {
      id: 'toPic',
      numeric: true,
      disablePadding: false,
      label: 'To PIC',
    },
    {
      id: 'species',
      numeric: true,
      disablePadding: false,
      label: 'Species',
    },
    {
      id: 'quantity',
      numeric: true,
      disablePadding: false,
      label: 'Quantity',
    },
    {
      id: 'accreditation',
      numeric: true,
      disablePadding: false,
      label: 'Accreditation',
    },
    {
      id: 'transporter',
      numeric: true,
      disablePadding: false,
      label: 'Transporter',
    },
    {
      id: 'mobile',
      numeric: true,
      disablePadding: false,
      label: 'Mobile',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'pdf',
      numeric: true,
      type: 'url',
      urlLabel: 'Download PDF',
      disablePadding: false,
      label: 'Pdf',
    },
  ];

  useEffect(() => {
    fetchData();
    fetchPic();
  }, [token]);
  
  useEffect(() => {
    fetchData()
  },[activePic])

  
  const fetchData = () => {
    setRows([])
    console.log(activePic,'activePic')
    if(activePic !== undefined){
      let picData = picList.find(data => data.pic === activePic)
      if(picData.lpaUsername === null ||picData.lpaPassword === null){
        alert('lpaUsername or lpaPassword is null')
        return;
      }
      setLoader(true);
      createApiData('admin/getEnvd',
      {"lpaUsername": picData.lpaUsername.split('-')[1],
      "lpaPassword": picData.lpaPassword,
      "pic": activePic}
      ,localStorage.getItem('session')).then(data => {
              
              data.data.data.data.consignments?.items.map((cons) => [
                setRows(
                  (prev) =>
                    (prev = [
                      ...prev,
                      createData(
                        cons.number,
                        cons.submittedAt,
                        cons.origin.pic,
                        cons.consignee.pic,
                        cons.species,
                        cons.answers[
                          cons.answers.findIndex((data) => data.questionId === '3')
                        ]?.value,
                        // 'Animal Health Statement',
                        getAccredentials(cons),
                        cons.answers[
                          cons.answers.findIndex((data) => data.questionId === '158')
                        ]?.value,
                        cons.answers[
                          cons.answers.findIndex((data) => data.questionId === '160')
                        ]?.value,
                        cons.status,
                        cons.pdfUrl
                      ),
                    ])
                ),
              ]);
            })
            .catch(err => setLoader(false))
            .finally(() => setLoader(false));
    }

    
  };

  const fetchPic = () => {
    fetchApiData('users/getAllPic',localStorage.getItem('session'))
    .then(data => {
      setPicList(data.data.data)
    })
  }

  function firstChar(string) {
    return string.charAt(0)
  }

  function getAccredentials(items) {
    var availableTypes = [];
    items.forms.map(form => {
      var type = form.type ?? '';
      switch (type) {
        case 'HSC2':
          availableTypes.push('NCHD');
          break;
        case 'HSS2':
          break;
        case 'HSSL1':
          availableTypes.push('NSHD');
          break;
        case 'MSAC1':
          availableTypes.push('MSA');
          break;
        case 'NFASDDC1':
          availableTypes.push('NFAS-DD');
          break;
        case 'NFASC0':
          availableTypes.push('NFAS-DD-B');
          break;
        case 'HSG0':
          availableTypes.push('NGHD');
          break;
        default:
          availableTypes.push(type);
          break;
      }
    })

    return availableTypes.join(' , ');
  }
  // const availableTypes = [];
  // const ahsType = 'HS' + firstChar(items.species);
  // const msaType = 'MSA' + firstChar(items.species);
  // const nfasType = 'NFAS' + firstChar(items.species);
  // const nchdType = 'NCHD' + firstChar(items.species);
  // const nshdType = 'NSHD' + firstChar(items.species);
  // const nghdType = 'NGHD' + firstChar(items.species);

  // const ahsResults =
  //   items.forms.filter((element) => (element.type ?? '').includes(ahsType));
  // if (!ahsResults.length) {
  //   const nchdResults =
  //     items.forms.filter((element) => (element.type ?? '').includes(nchdType));
  //   if (!nchdResults.length) availableTypes.push('NCHD');
  //   const nshdResults =
  //     items.forms.filter((element) => (element.type ?? '').includes(nshdType));
  //   if (!nshdResults.length) availableTypes.push('NSHD');
  //   const nghdResults =
  //     items.forms.filter((element) => (element.type ?? '').includes(nghdType));
  //   if (!nghdResults.length) availableTypes.push('NGHD');

  // };

  // const msaResults =
  //   items.forms.filter((element) => (element.type ?? '').includes(msaType));
  // if (!msaResults.length) availableTypes.push('MSA');
  // const nfasResults =
  //   items.forms.filter((element) => (element.type ?? '').includes(nfasType));
  // if (!nfasResults.length) availableTypes.push('NFAS');
  // const result = availableTypes.join(' , ');
  // console.log(result)
  // return result;

  return (
    <>
      <HeaderName />
      <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between'}}>
      <Typography variant="h5" id="tableTitle" component="div" padding={1}>
        eNVD
      </Typography>
      <FormControl style={{marginRight: 8}} >
  <InputLabel id="demo-simple-select-label">Select PIC</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label="Select PIC"
    onChange={value => setActivePic(value.target.value)}
    name="productName"
    style={{minWidth: 150}}
  >
  <MenuItem disabled selected value={''}>Select an Option</MenuItem>
    {picList.map(data => 
    <MenuItem value={data.pic}>{data.pic}</MenuItem>)}
  </Select>
</FormControl>
      </div>

      <div style={{ display: 'flex' }}>
        <EnhancedTable
          apiId="envd/"
          fetchData={fetchData}
          rows={rows}
          setRows={setRows}
          headCells={headCells}
        />
      </div>
    </>
  );
};

export default Envd;
