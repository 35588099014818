import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import moment from 'moment';

import { confirmAlert } from 'react-confirm-alert';
import { visuallyHidden } from '@mui/utils';
import { Autocomplete, Button, Chip, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NavigationIcon from '@mui/icons-material/Navigation';

import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteApiData, updateApiData } from './Api';
import MapComponent from './Map';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort,headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        {headCells?.map((headCell,index) => (
          headCell.type !== 'hidden' && (
          <TableCell
            key={index}
            align='center'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          )
        ))}
      </TableRow>
    </TableHead>
  );
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default function EnhancedTable({apiId,fetchData,rows,setRows,headCells,appendSubmit,customSubmit}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [formModal, setFormModal] = React.useState(false);
  const [mapModal, setMapModal] = React.useState(false);
  const [activeId, setActiveId] = React.useState(0);
  const [activeFormData, setActiveFormData] = React.useState();
  const [modalType, setModalType] = React.useState()
  const [multiTagValue, setMultiTagValue] = React.useState([])
  const [locationPoints,  setLocationPoints] = React.useState([])

  const [tableData, setTableData] = React.useState([])


  const handleOpenModal = (eventId) => {
    
    setActiveId(id=> id= tableData.findIndex(data => data.id === eventId))
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  const handleOpenFormModal = (data) => {
    
    setActiveFormData(data)
    setFormModal(true);
  };

  const handleCloseFormModal = () => {
    setFormModal(false);
  };

  const handleOpenMapModal = (id) => {
    setLocationPoints([])
    let mapData = rows.find(data => data.id == id)
    mapData.locations.coordinates[0].map(points => setLocationPoints(prevData => [...prevData,{ lat: points[1], lng: points[0]}]))
    setMapModal(true);
  };

  const handleCloseMapModal = () => {
    setMapModal(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleDeleteRow = (eventId) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div style={{background: 'white',border: "2px solid grey", paddingBottom: 25,paddingInline: 35, borderRadius: 10, display: 'flex',flexDirection: 'column',gap: 5}}>
            <h2>Confirm to Delete</h2>
            <h3 style={{marginTop: -20}}>Are you sure ?</h3>
            <div>

            <Button  variant="contained"
              style={{marginRight: "10px"}}
              onClick={() => {
                deleteApiData(apiId,eventId,localStorage.getItem('session'))
            .then(data => {
              alert(data.data.data)
              setRows(prev => prev = prev.filter(data => data.id !== eventId))
              onClose();
            })
                
              }}
            >
              Yes
            </Button>
            <Button variant="outlined" onClick={onClose}>No</Button>
            </div>
            
          </div>
        );
      }
      
    });
  }

  const stringToBoolean = (stringValue) => {
    switch(stringValue?.toLowerCase()?.trim()){
        case "true": 
          return true;
          
        default: {
          return false;
        }  
    }
}
  
  const handleEditData = (event) => {
    event.preventDefault();
    if(customSubmit){
      customSubmit(event,setOpenModal)
      return;
    }
    var tempData = {}
    
    try{
    headCells.map(data => { 
      if(data.id !== "actions" && data.id !== "id" & !data.disableEdit && event.target['role']?.value !== 'Admin'){
        if(data.type === 'multiSelectTag'){
          tempData[data.id] = event.target[data.id].value.split(',')
        }else if(data.dataType){
          switch(data.dataType){
            case 'boolean': 
              tempData[data.id] = stringToBoolean(event.target[data.id].value)
              break;
            case 'integer':
              tempData[data.id] = Number(event.target[data.id].value)
              break;
            default: 
              tempData[data.id] = event.target[data.id].value
              break;
          }
        }else{
          tempData[data.id] = event.target[data.id].value
        }
      }
      return ''
    })
    updateApiData(apiId,event.target['id'].value,tempData,localStorage.getItem('session'))
    .then(data => {
      setOpenModal(false)
      if(appendSubmit){
        appendSubmit(event,rows)
      }
      fetchData()
      alert("Updated Successfully")
    })
    .catch(err => {
      alert(err.response.data.message)
    })
  }catch (err) {
    alert(err)
  }
  } 

  React.useEffect(() => {
    setTableData(rows)
  },[rows])
  
  return (
    <>
    <Box sx={{ maxWidth: '99%',}}>
      <Paper sx={{ width: '100%', mb: 2,boxShadow: '0px 1px 5px 1px rgb(0 0 0 / 20%)' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
            >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={tableData.length}
              headCells={headCells}
              />
            <TableBody> 
              {stableSort(tableData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow
                    
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    >
                      
                      {headCells.map((cell) => (
                        cell.type !== 'hidden' && (
                        cell.id === "actions" ? cell.type === 'custom' ? 
                        <TableCell key={cell.id} align="center" sx={{display: "flex",flexDirection: "row",justifyContent: "center",minHeight: 40}}>
                          {row[cell.id]}
                        </TableCell>
                         :
                        <TableCell key={cell.id} align="center" sx={{display: "flex",flexDirection: "row",justifyContent: "center",minHeight: 40}}>
                          
                          <IconButton variant="text"  onClick={() => {
                            setModalType(type => type="Edit")
                            handleOpenModal(row['id'])
                          }}><EditOutlinedIcon/></IconButton>
                          {
                            cell.includeMap === true && <IconButton variant="text" onClick={() => {
                            handleOpenMapModal(row['id'])
                          }}><NavigationIcon/></IconButton>
                          }
                          {cell.onlyEdit !== true && 
                          <>
                          <IconButton variant="text" onClick={() => {
                            setModalType(type => type="View")
                            handleOpenModal(row['id'])
                          }}><RemoveRedEyeOutlinedIcon/></IconButton>
                          <IconButton variant="text" onClick={() => {
                            handleDeleteRow(row['id'])
                          }}><DeleteOutlineOutlinedIcon/></IconButton>
                          </>
                          } 

                        </TableCell>
                        : <TableCell align="center" padding='normal' key={cell.id}>
                          {cell.type === 'url' ? <Button size="small" href={row[cell.id]} target="_blank">{cell.urlLabel}</Button> 
                          : cell.type === 'date' ? <div style={{maxWidth: 100}}>{moment(row[cell.id]).format('DD/MM/YYYY hh:mm A') !== 'Invalid date' && moment(row[cell.id]).format('DD/MM/YYYY hh:mm A')}</div> 
                          : cell.type === 'select' ? cell.collection.find(data => data.id == row[cell.id])?.label
                          : cell.type === 'form' ? row[cell.id] !== null ? <Button onClick={() => handleOpenFormModal(row[cell.id])}>View</Button> : <Button disabled>unregistered</Button>
                          : cell.type === 'multiSelectTag' && row[cell.id] !== null ? row[cell.id].join(',')
                          : cell.type === 'phoneNumber' && row[cell.id] !== null ? row[cell.id].countryCode+' '+row[cell.id].number
                          : cell.type === 'price' && row[cell.id] !== null ? '$ '+row[cell.id]
                          : cell.type === 'number' ? row[cell.id]
                          : !cell.type && row[cell.id]}
                          </TableCell>
                        )
                        )
                        )}
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableData.length}
          labelDisplayedRows={({ page }) => {
            return `Page: ${page+1} of ${parseInt(tableData.length/rowsPerPage)+1}`;
          }}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>
    </Box>

    {/* Edit View Modal */}
    <Modal
        hideBackdrop
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form onSubmit={handleEditData}>
          <div>
        
          <h2 id="child-modal-title">{modalType}</h2>
          
          {headCells.map(cell => (cell.type === "select"? <FormControl key={cell.id}>
                          <InputLabel id="select-label">{cell.label}</InputLabel>
                          <Select
                            sx={{margin: 1}}
                            labelId="select-label"
                            id="select-createdBy"
                            defaultValue={tableData[activeId] !== undefined && tableData[activeId][cell.id]}
                            name={cell.id}
                            onChange={value => {
                              tableData[activeId][cell.id] = value.target.value
                              }}
                          >
                            {cell.collection.map(data => <MenuItem key={data.id} value={data.id}>{data.label}</MenuItem>)}
                            
                          </Select>
                          </FormControl>: cell.type === "multiSelectTag" ?  (
                            <FormControl key={cell.id}>
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            freeSolo={cell.freeSolo ? true : false}
                            options={cell.collection !== undefined ? cell.collection : []}
                            defaultValue={tableData[activeId] !== undefined ? tableData[activeId][cell.id]:[]}
                            name={cell.id}
                            onChange= {(value,collection)=> {value && setMultiTagValue(collection)}}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <>
                                {option !== '' && <Chip name={cell.id} variant="outlined" label={option} {...getTagProps({ index })} />}
                                </>
                              )
                              )
                            }
                            renderInput={(params) => (
                              <>
                              <TextField
                                {...params}
                                label={cell.label}
                                sx={{margin: 1, width: 460}}
                                placeholder={cell.label}
                              />
                              <input
                              name={cell.id}
                              value={multiTagValue}
                              hidden={true}
                              />
                              </>
                            )}
                          /></FormControl>): cell.type == 'phoneNumber' && tableData.length !== 0 ? <TextField
                          key={cell.id}
                          sx={{margin: 1}}
                          id="outlined-required"
                          inputProps={
                            { readOnly: modalType === "View" ? true : false, }
                          }
                          disabled ={cell.disableEdit ? true : false}
                          label={cell.label}
                          type='number'
                          name={cell.id}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {tableData[activeId][cell.id].countryCode}
                              </InputAdornment>
                            ),
                          }}
                          defaultValue={tableData[activeId][cell.id].number}
                        /> : cell.type == 'number' && tableData.length !== 0 ? <TextField
                          key={cell.id}
                          sx={{margin: 1}}
                          id="outlined-required"
                          disabled ={cell.disableEdit ? true : false}
                          label={cell.label}
                          inputProps={
                                { readOnly: modalType === "View" ? true : false, }
                              }
                          type='number'
                          name={cell.id}
                          defaultValue={tableData[activeId][cell.id]}
                        /> : cell.id !== "actions" && cell.type !== "hidden" && tableData.length !== 0 &&
                              <TextField
                              key={cell.id}
                              sx={{margin: 1}}
                              id="outlined-required"
                              inputProps={
                                { readOnly: modalType === "View" ? true : false, }
                              }
                              disabled ={cell.disableEdit ? true : false}
                              label={cell.label}
                              name={cell.id}
                              defaultValue={tableData[activeId][cell.id]}
                            />))}
          </div>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={handleCloseModal}>Cancel</Button>
          {modalType !== "View" && <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>}
          
        </div>
        </form>
        </Box>
      </Modal>
      
      {/* form modal */}
      <Modal
        hideBackdrop
        open={formModal}
        onClose={handleCloseFormModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form>
          <Box sx={{height: 500, overflow: 'scroll',overflowX: 'hidden', marginBottom: 2}}>
        
          <h2 id="child-modal-title">Declaration Form</h2>
          
          {activeFormData?.map(cell => (
            cell.type === 'field' ?
          <TextField
          key={cell.id}
          inputProps={
            { readOnly: true, }
          }
          sx={{margin: 1,color: 'black'}}
          id="outlined-required"
          label={cell.key}
          name={cell.id}
          value={cell.value}
        />: <div key={cell.id}><Typography id="question">{cell.key}</Typography><TextField
        inputProps={
					{ readOnly: true, }
				}
        sx={{margin: 1, width: 400}}
        id="outlined-required"
        name={cell.id}
        value={cell.value}

      /></div>))}
          </Box>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={handleCloseFormModal}>Close</Button>
          {/* {modalType !== "View" && <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>} */}
          
        </div>
        </form>
        </Box>
      </Modal>

      {/* fMaps Modal */}
      <Modal
        hideBackdrop
        open={mapModal}
        onClose={handleCloseMapModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form>
          <Box sx={{height: 500, marginBottom: 2}}>
        
          <h2 id="child-modal-title">Maps</h2>
          
          <MapComponent markers={locationPoints}/>
          </Box>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={handleCloseMapModal}>Close</Button>
          {/* {modalType !== "View" && <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>} */}
          
        </div>
        </form>
        </Box>
      </Modal>
    </>
  );
}