import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArticleIcon from '@mui/icons-material/Article';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Logo from '../assets/transparentLogo.png'

import { Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { grey } from '@mui/material/colors';



const data = [
  { icon: <HomeIcon/>, label: 'Dashboard', page: '/' },
  { icon: <GroupIcon/>, label: 'Users', page: '/users' },
  { icon: <LocationOnIcon/>, label: 'Geofences', page: '/geofences' },
  { icon: <GroupIcon/>, label: 'Records', subOptions: [
    {label: 'Attendance', page: '/attendance' },
    {label: 'Leave', page: '/leave' },
    {label: "Log records", page: '/logrecords'},
    {label: "Notifications", page: '/notifications'},
    {label: "SOS", page: '/sos'}
  ],isOpen: true
 },
 { icon: <ArticleIcon/>, label: 'CVD', page: '/cvd' },
//  { icon: <ArticleIcon/>, label: 'eNVD', page: '/envd' },
 { icon: <CreditCardIcon/>, label: 'Payments', page: '/payments' },
 { icon: <LogoutIcon/>, label: 'Sign Out', page: '/signout'}
];

const adminData = [
  { icon: <HomeIcon/>, label: 'Dashboard', page: '/' },
  { icon: <GroupIcon/>, label: 'Users', page: '/users' },
  { icon: <LocationOnIcon/>, label: 'Geofences', page: '/geofences' },
  { icon: <GroupIcon/>, label: 'Records', subOptions: [
    {label: 'Attendance', page: '/attendance' },
    {label: 'Leave', page: '/leave' },
    {label: "Log records", page: '/logrecords'},
    {label: "Notifications", page: '/notifications'},
    {label: "SOS", page: '/sos'}
  ],isOpen: true
 },
 { icon: <ArticleIcon/>, label: 'CVD', page: '/cvd' },
//  { icon: <ArticleIcon/>, label: 'eNVD', page: '/envd' },
 { icon: <CreditCardIcon/>, label: 'Payments', page: '/payments' },
 { icon: <CreditCardIcon/>, label: 'Subscription Plans', page: '/subscription' },
 { icon: <CreditCardIcon/>, label: 'Global Plan Addon', page: '/globalPlanAddon' },
  { icon: <LogoutIcon/>, label: 'Sign Out', page: '/signout'}
];

const managerData = [
  { icon: <HomeIcon/>, label: 'Dashboard', page: '/' },
  { icon: <GroupIcon/>, label: 'Users', page: '/users' },
  { icon: <LocationOnIcon/>, label: 'Geofences', page: '/geofences' },
  { icon: <GroupIcon/>, label: 'Records', subOptions: [
    {label: 'Attendance', page: '/attendance' },
    {label: 'Leave', page: '/leave' },
    {label: "Log records", page: '/logrecords'},
    {label: "Notifications", page: '/notifications'},
    {label: "SOS", page: '/sos'}
  ],isOpen: true
 },
 { icon: <ArticleIcon/>, label: 'CVD', page: '/cvd' },
//  { icon: <ArticleIcon/>, label: 'eNVD', page: '/envd' }, 
  { icon: <LogoutIcon/>, label: 'Sign Out', page: '/signout'}
];

const CustNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export default function NavBar({activePage}) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    var session = localStorage.getItem('session')
    if(session === null){
      navigate('/login')
    }
  },[])

  return (
    <Box sx={{ display: 'flex',backgroundColor: "#eee",height: "100vh",zIndex: 200, position: 'sticky',top:'0px' }}>
          <CustNav component="nav" sx={{bgcolor: grey,width: "100%", overflow: 'scroll'}} disablePadding>
            <ListItemButton component="a"  sx={{
                mb: 5,
                mt: 5
            }}>
              <ListItemIcon sx={{ fontSize: 20 }}><img src={Logo} style={{height : 40}}/></ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary={localStorage.getItem('role') === 'Admin' ? 'BIOPLUS Admin' : localStorage.getItem('role') === 'Corporate' ? 'BIOPLUS Corporate' : 'BIOPLUS Manager'}
                secondary={localStorage.getItem('userName')}
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
              
            </ListItemButton>
            

                
            <List
      sx={{ width: '100%', maxWidth: 360, }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      
    >
        {
       localStorage.getItem('role') === 'Admin' ?
          adminData.map(a => 
        (

            <div key={a.label} style={{ display: a === false ? 'none' : 'block'}}>
      <ListItemButton style={{backgroundColor: a.page === activePage && "grey", color: a.page === activePage && 'white'}} onClick={()=> a.subOptions ? setOpen(prev => prev = !open): navigate(a.page)}>
        <ListItemIcon style={{color: a.page === activePage && 'white'}}>
            {a.icon}
        </ListItemIcon>
        <ListItemText primary={a.label} />
        {a.subOptions && open && <KeyboardArrowDownIcon /> }
        {a.subOptions && !open && <KeyboardArrowRightIcon /> }
      </ListItemButton>
        {a.subOptions && <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{a.subOptions.map((b)=> (
            <ListItemButton key={b.label} sx={{ ml: 2.5, bgcolor: b.page === activePage && "grey", color: b.page === activePage && "white" }} onClick={()=> navigate(b.page)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText primary={b.label} />
          </ListItemButton>
        ))}</List></Collapse>}
        </div>
        )):
        localStorage.getItem('role') === 'Corporate' ?
        data.map(a => 
        (

            <div key={a.label} style={{ display: a === false ? 'none' : 'block'}}>
      <ListItemButton style={{backgroundColor: a.page === activePage && "grey", color: a.page === activePage && 'white'}} onClick={()=> a.subOptions ? setOpen(prev => prev = !open): navigate(a.page)}>
        <ListItemIcon style={{color: a.page === activePage && 'white'}}>
            {a.icon}
        </ListItemIcon>
        <ListItemText primary={a.label} />
        {a.subOptions && open && <KeyboardArrowDownIcon /> }
        {a.subOptions && !open && <KeyboardArrowRightIcon /> }
      </ListItemButton>
        {a.subOptions && <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{a.subOptions.map((b)=> (
            <ListItemButton key={b.label} sx={{ ml: 2.5, bgcolor: b.page === activePage && "grey", color: b.page === activePage && "white" }} onClick={()=> navigate(b.page)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText primary={b.label} />
          </ListItemButton>
        ))}</List></Collapse>}
        </div>
        )):
        localStorage.getItem('role') === 'Manager' &&
        managerData.map(a => 
        (

            <div key={a.label} style={{ display: a === false ? 'none' : 'block'}}>
      <ListItemButton style={{backgroundColor: a.page === activePage && "grey", color: a.page === activePage && 'white'}} onClick={()=> a.subOptions ? setOpen(prev => prev = !open): navigate(a.page)}>
        <ListItemIcon style={{color: a.page === activePage && 'white'}}>
            {a.icon}
        </ListItemIcon>
        <ListItemText primary={a.label} />
        {a.subOptions && open && <KeyboardArrowDownIcon /> }
        {a.subOptions && !open && <KeyboardArrowRightIcon /> }
      </ListItemButton>
        {a.subOptions && <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>{a.subOptions.map((b)=> (
            <ListItemButton key={b.label} sx={{ ml: 2.5, bgcolor: b.page === activePage && "grey", color: b.page === activePage && "white" }} onClick={()=> navigate(b.page)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText primary={b.label} />
          </ListItemButton>
        ))}</List></Collapse>}
        </div>
        ))
        
        }
      
    </List>
              
            
            
          </CustNav>
    </Box>
  );
}