import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import { Button, Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { useExcelDownloder } from '@coinsummer/react-xls';
import { fetchApiData } from '../../Components/Api';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';


function createData(visitorName,zone,company,serviceRole, entryDate,exitDate,picngr,postCode,declaration) {
  return {
    visitorName,
    zone, 
    company,
    serviceRole,
    entryDate,
    exitDate,
    picngr,
    postCode,
    declaration
  };
}


const LogRecords = () => {
  const [loader,setLoader] = useOutletContext();
  const { ExcelDownloder, Type } = useExcelDownloder();
  const [rows, setRows] = useState([])
  const [excelData,setExcelData] = useState({
    BioPlusLogRecords : [
    ],
  })
  
  const headCells = [
    {
      id: 'visitorName',
      numeric: false,
      disablePadding: false,
      label: 'Visitor Name',
    },
    {
      id: 'zone',
      numeric: true,
      disablePadding: false,
      label: 'Zone',
    },
    {
      id: 'company',
      numeric: true,
      disablePadding: false,
      label: 'Company',
    },
    {
      id: 'serviceRole',
      numeric: true,
      disablePadding: false,
      label: 'Service Role',
    },
    {
      id: 'entryDate',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Entry Date',
    },
    {
      id: 'exitDate',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Exit Date',
    },
    {
      id: 'picngr',
      numeric: true,
      disablePadding: false,
      label: 'PIC/NGR',
    },
    {
      id: 'postCode',
      numeric: true,
      disablePadding: false,
      label: 'Post Code',
    },
    {
      id: 'declaration',
      type: 'form',
      numeric: true,
      disablePadding: false,
      label: 'Declaration',
    },
  ];

  const getDeclarationQuestions = (object)=> {
    if(object.declarationForm === null){
      return null
    }
    switch (object.declarationForm.type) {
      case 'global': {
        return [
          {type: 'field',key: 'Full Name',value: object.user.firstName+" "+object.user.lastName},
          {type: 'field',key: 'Phone Number',value: object.user.countryCode+" "+object.user.phoneNumber},
          {type: 'field',key: 'Company Name',value: object.user.company},
          {type: 'field',key: 'Expected Departure',value: moment(object.declarationForm.expectedDepartureDate).format('DD/MM/YYYY hh:mm A')},
          {type: 'question',key: '1. Are other people travelling onto the property with you?',value: object.declarationForm.userTravelingAlong?.length > 0 ? object.declarationForm.userTravelingAlong.join(','): 'No'},
          {type: 'question',key: '2. Have all visitors had a Q Fever vaccination?',value: object.declarationForm.isQfeverVaccinated === true ? 'Yes':'No'},
          {type: 'question',key: '3. Does anyone have cold or flu symptoms?',value: object.declarationForm.isFluSymptoms === true ? 'Yes':'No'},
          {type: 'question',key: '4. Have any visitors been overseas in the last 7 days?',value: object.declarationForm.isOverSeaVisit === true ? 'Yes':'No'},
          {type: 'question',key: '5. Have you taken all reasonable measures to ensure that shoes, clothing and equipment are free from potential weed and disease contaminants:',value: object.declaration?.isAllMeasureTaken === true ? 'Yes':'No'},
          {type: 'question',key: '6. Have you made reasonable attempts to notify the owner/manager of the property that you are visiting today?',value: object.declarationForm.isOwnerNotified === true ? 'Yes':'No'},
          {type: 'question',key: 'Rego',value: object.declarationForm.rego},
          {type: 'question',key: 'Risk Rating',value: object.declarationForm.riskRating},
        ]
      }
      case 'warakirri': {
        return [{type: 'field',key: 'Full Name',value: object.user.firstName+" "+object.user.lastName},
        {type: 'field',key: 'Phone Number',value: object.user.countryCode+" "+object.user.phoneNumber},
        {type: 'field',key: 'Company Name',value: object.user.company},
        {type: 'field',key: 'Expected Departure',value: moment(object.declarationForm.expectedDepartureDate).format('DD/MM/YYYY hh:mm A')},
        {type: 'question',key: '1. Are other people travelling onto the property with you?',value: object.declarationForm.userTravelingAlong?.length > 0 ? object.declarationForm.userTravelingAlong.join(','): 'No'},
        {type: 'question',key: '2. Do you have any of the following symptoms: feeling unwell or displaying any flu-like symptoms, sudden loss of smell or taste, fever, cough, sore throat, fatigue or shortness of breath?',value: object.declarationForm.isFluSymptoms === true ? 'Yes':'No'},
        {type: 'question',key: '3. Have you been overseas in the past 7 days ?',value: object.declarationForm.isOverSeaVisit === true ? 'Yes':'No'},
        {type: 'question',key: '4. Have you been inducted to this site',value: object.declarationForm.hasBeenInducted === true ? 'Yes':'No'},
        {type: 'question',key: '5. Does the work being undertaken involve exposure to Confined Spaces,  Native Vegetation Clearing, Work at Heights or Firearm Use. Are you working on any plant that needs to be isolated?',value: object.declarationForm.isConfinedSpace === true ? 'Yes':'No'},
        {type: 'question',key: 'Additional Info',value: object.declarationForm.additionalInfo},
        {type: 'question',key: 'Location Name',value: object.declarationForm.warakirriFarm},
      ]
      }
      default:{
        return null
      }
    }
  }

  

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/record/log?skip=0&limit=100',localStorage.getItem('session')).then(data => {
      console.log(data)
      setRows([])
      
      data.data.data.map(data => {
      var picngr = ""
      if(data.geofence.pic === "" || data.geofence.pic === null){
        picngr = data.geofence.ngr
      }else{
        picngr = data.geofence.pic
      }
        setRows(prev => prev = ([...prev, createData(data.user.firstName+" "+data.user.lastName,data.geofence.name,data.geofence.companyOwner,data.user.role,data.enterDate,data.exitDate,picngr,data.user.postcode,getDeclarationQuestions(data))]))
        setExcelData(prev => prev = ({...prev, BioPlusLogRecords: [...prev.BioPlusLogRecords, {"Visitor Name":data.user.firstName+" "+data.user.lastName,"Zone": data.geofence.name,"Company":data.geofence.companyOwner,"Service Role": data.user.role,"Entry Date": data.enterDate,"Exit Date": data.exitDate,"PIC/NGR": picngr,"Post Code": data.user.postcode}]}))
        
        return ''
      })
    }).finally(()=> setLoader(false))
  }

  useEffect(()=> {
    fetchData()
  },[])

  return (
    <>
  <HeaderName/>
    <div style={{display: "flex", justifyContent:'space-between'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Log Records
        </Typography>
    {excelData.BioPlusLogRecords != '' &&
        <ExcelDownloder
        data={excelData}
        filename={'Bioplus Log Records'}
        type={Type.Link} // or type={'link'}
        >
        <Button variant='outlined' style={{marginRight: 20}}>Download XLS</Button>
      </ExcelDownloder>
        }
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
  </>
  )
}

export default LogRecords