import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GroupIcon from '@mui/icons-material/Group';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';

import HeaderName from '../../Components/HeaderName'
import EnhancedTable from '../../Components/EnhancedTable';
import { fetchApiData } from '../../Components/Api';
import { useOutletContext } from 'react-router-dom';
import formatAmount from '../../Components/formatAmount';
import moment from 'moment';

function createData(visitorName, zone, entryDate, exitDate) {
    return {
        visitorName, 
        zone, 
        entryDate, 
        exitDate
    };
  }

const Dashboard = () => {
    const [loader,setLoader] = useOutletContext();
    const [users, setUsers] = useState()
    const [geofence, setGeofence] = useState()
    const [payments, setPayments] = useState()
    const [cvds, setCvds] = useState()
    const [logRecords, setLogrecords] = useState()
    const [notifications, setNotifications] = useState()
    const [sos, setSos] = useState()
    const [company, setCompany] = useState([])

    const style = {
        fontSize: 37, 
        width: '300px',
        height: '200px', 
        margin: '10px',
        borderRadius: '20px', 
        boxShadow: '0px 1px 5px 1px rgb(0 0 0 / 20%)', 
        display: 'flex', 
        flexDirection:'column', 
        justifyContent: 'center',
        alignItems: 'baseline',
        padding: '0 20px',
        flexShrink: 1,
    }
    const styleSmall = {
        height: '120px', 
        margin: '10px',
        borderRadius: '5px', 
        boxShadow: '0px 1px 5px 1px rgb(0 0 0 / 20%)',
        display: 'flex', 
        flexDirection:'column', 
        justifyContent: 'flex-start',
        alignItems: 'center'
    }

    const [rows,setRows] = useState([]);
  const headCells = [
    {
      id: 'visitorName',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Visitor Name',
    },
    {
      id: 'zone',
      numeric: false,
      disablePadding: false,
      label: 'Zone',
    },
    {
      id: 'entryDate',
      numeric: false,
      type: 'date',
      disablePadding: false,
      label: 'Enter Date',
    },
    {
      id: 'exitDate',
      numeric: true,
      type: 'date',
      disablePadding: false,
      label: 'Exit Date',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/dashboard',localStorage.getItem('session')).then(data => {
      console.log(data)
      fetchApiData('users/me',localStorage.getItem('session')).then((userData) => {
        setCompany(userData.data.data.company)
        console.log('user data ',userData.data.data.company)
      })
      setRows([])
        setUsers(data.data.data.userCount)
        setGeofence(data.data.data.geofenceCount)
        setPayments(data.data.data.paymentTotalCount+' / $'+formatAmount(data.data.data.paymentCount))
        setCvds(data.data.data.cvdCount)
        setLogrecords(data.data.data.logCount)
        setNotifications(data.data.data.notificationCount)
        setSos(data.data.data.SosCount)
        data.data.data.logRecords.map(data => {
            setRows(prev => prev = ([...prev, createData(data.user.firstName+" "+data.user.lastName,data.geofence.name,data.enterDate,data.exitDate)]))
        })
        return ''
    }).finally(() => {
      console.log(rows)
      setLoader(false)
    })
  }

  useEffect(()=> {
    fetchData()
  },[])


  return (<>
  <HeaderName/>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Dashboard
        </Typography>
    <div style={{width: '100%',display: "flex", flexDirection: 'row',justifyContent: 'space-evenly' }}>
    {localStorage.getItem('role') !== 'Admin' && <div style={style}><BusinessIcon fontSize='50'/><span style={{fontSize: 37,}}>Company</span><div style={{fontWeight: '400',fontSize: 20}}>{company.join(',')}</div></div>}
    <div style={style}><GroupIcon fontSize='50'/> <span style={{fontSize: 37,}}>Users</span><div style={{fontWeight: '400',fontSize: 30}}>{users}</div></div>
    <div style={style}><LocationOnIcon fontSize='50'/> <span style={{fontSize: 37,}}>Geofence</span><div style={{fontWeight: '400',fontSize: 30}}>{geofence}</div></div>
    {localStorage.getItem('role') === 'Admin' && <div style={style}><div style={{fontSize: '20px', fontWeight: '500', position: 'relative', left: '100px', top: '30px'}}>{moment().format('MMMM')}</div> <CreditCardIcon fontSize='50'/><span style={{fontSize: 37,}}>Payments</span><div style={{fontWeight: '400',fontSize: 25}}>{payments}</div></div>}

    <div style={style}><div style={{fontSize: '20px', fontWeight: '500', position: 'relative', left: '100px', top: '30px'}}>{moment().format('MMMM')}</div><ArticleIcon fontSize='50'/> <span style={{fontSize: 37,}}>CVD's</span><div style={{fontWeight: '400',fontSize: 30}}>{cvds}</div></div>
       
    </div>
    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
    <div style={{flex: 1,display: "flex", flexDirection: 'column',justifyContent: 'flex-start' }}>
        <div style={styleSmall}><span style={{backgroundColor: 'black',borderTopRightRadius: '5px',borderTopLeftRadius: '5px',width: '100%', textAlign: 'center',fontSize: 30, fontWeight :500, padding: "10px 0px", color: 'white'}}>Log Records</span><div style={{width: '100%', textAlign: 'center',fontSize: 30, fontWeight :400, padding: "10px 0px"}}>{logRecords}</div></div>
        <div style={styleSmall}><span style={{backgroundColor: 'black',borderTopRightRadius: '5px',borderTopLeftRadius: '5px',width: '100%', textAlign: 'center',fontSize: 30, fontWeight :500, padding: "10px 0px", color: 'white'}}>Notifications</span><div style={{width: '100%', textAlign: 'center',fontSize: 30, fontWeight :400, padding: "10px 0px"}}>{notifications}</div></div>
        <div style={styleSmall}><span style={{backgroundColor: 'black',borderTopRightRadius: '5px',borderTopLeftRadius: '5px',width: '100%', textAlign: 'center',fontSize: 30, fontWeight :500, padding: "10px 0px", color: 'white'}}>SOS</span><div style={{width: '100%', textAlign: 'center',fontSize: 30, fontWeight :400, padding: "10px 0px"}}>{sos}</div></div>
    </div>
    <div style={{maxWidth: '70%',flex: 3,marginRight: '10px', marginTop: '10px'  }}>
        <div style={{borderRadius: '5px'}}>

    <EnhancedTable apiId="admin/dashboard" fetchData={fetchData} rows={rows} setRows={setRows} headCells={headCells}/>
        </div>

    </div>

    </div>
  </>)
}

export default Dashboard