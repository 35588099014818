import React from 'react'
import LoaderSvg from '../assets/loader.svg'

const Loading = ({visible}) => {
  return (
    <div 
    style={{display: visible ? 'block': 'none',
            position: 'fixed',
            backgroundColor:'#00000080',
            height: '100vh', 
            width: '100vw',
            zIndex: 400, 
            padding: '50vh 50vw'
            }}>
                <img height="100px" width="100px" src={LoaderSvg}/>
        </div>
  )
}

export default Loading