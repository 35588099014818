import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import { Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { fetchApiData } from '../../Components/Api';
import { useOutletContext } from 'react-router-dom';


function createData(name, date, zone, location, emergencyEmail,emergencyNumber) {
  return {
    name,
    date, 
    zone,
    location,
    emergencyEmail,
    emergencyNumber
  };
}


const SOS = () => {
  const [loader,setLoader] = useOutletContext();
  const [rows, setRows] = useState([])
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'date',
      numeric: true,
      type: 'date',
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'zone',
      numeric: true,
      disablePadding: false,
      label: 'Zone',
    },
    {
      id: 'location',
      numeric: true,
      type: 'url',
      urlLabel: 'View Location',
      disablePadding: false,
      label: 'Location',
    },
    {
      id: 'emergencyEmail',
      numeric: true,
      disablePadding: false,
      label: 'Emergency Email Id',
    },
    {
      id: 'emergencyNumber',
      numeric: true,
      disablePadding: false,
      label: 'Emergency Mobile No.',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/record/sos',localStorage.getItem('session')).then(data => {
      console.log(data)
      setRows([])
      data.data.data.map(data => {
        console.log(data.createdBy.contactName)
        setRows(prev => prev = ([...prev, createData(data.createdBy.firstName+" "+data.createdBy.lastName,data.sosDate,!data.geofence ? '-' : data.geofence?.name,data.locationUrl,data.createdBy.email,data.createdBy.countryCode+data.createdBy.emergencyMobileContact)]))
        
        return ''
      })
    }).finally(() => setLoader(false))
  }

  useEffect(()=> {
    fetchData()
  },[])

  return (
    <>
  <HeaderName/>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          SOS
        </Typography>
    <div style={{display: "flex"}}>
        
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
  </>
  )
}

export default SOS