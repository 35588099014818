import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import { Button, Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { fetchApiData } from '../../Components/Api';
import { useOutletContext } from 'react-router-dom';
import formatAmount from '../../Components/formatAmount';
import { useExcelDownloder } from '@coinsummer/react-xls';


function createData(name,company, role, date, isTrialMode,subscriptionEndDate,amount,monthlyannual,status,invoice) {
  return {
    name,
    company, 
    role,
    date,
    isTrialMode,
    subscriptionEndDate,
    amount,
    monthlyannual,
    status,
    invoice
  };
}



const Payments = () => {
  const [loader,setLoader] = useOutletContext();
  const [rows, setRows] = useState([])
  const [excelData, setExcelData] = useState({
    bioplusPayment: [
    ],
  })
  const {Type, ExcelDownloder} = useExcelDownloder()
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'company',
      numeric: true,
      disablePadding: false,
      label: 'Company',
    },
    {
      id: 'role',
      numeric: true,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'date',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'isTrialMode',
      numeric: true,
      disablePadding: false,
      label: 'On Trial',
    },
    
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'monthlyannual',
      numeric: true,
      disablePadding: false,
      label: 'Monthly/Annual',
    },
    {
      id: 'subscriptionEndDate',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Subscription End',
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'invoice',
      type: 'url',
      urlLabel: 'View Invoice',
      numeric: true,
      disablePadding: false,
      label: 'View Invoice',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    fetchApiData('payment/',localStorage.getItem('session')).then(data => {
      console.log(data)
      setRows([])
      
      data.data.data.map(data => {
      
        setRows(prev => prev = ([...prev, createData(data.user !== null ? data.user.firstName+' '+data.user.lastName : 'null',data.user?.company,data.role,data.createdAt,data.isTrialMode === true ? 'Yes' : 'No',data.subscriptionEndDate,data?.amount == null && data?.amount == undefined ? "$ 0.00" : '$ '+formatAmount(data?.amount)+'.00',data.type,data.status,data.invoicePdf)]))
        
        setExcelData(prev => prev = ({...prev, bioplusPayment: [...prev.bioplusPayment,{
          'Name': data.user !== null ? data.user.firstName+' '+data.user.lastName : 'null',
          "Company": data.user?.company,
          "Role": data.role,
          "Date": data.createdAt,
          "On Trial": data.isTrialMode === true ? 'Yes' : 'No',
          "Subscription End": data.subscriptionEndDate,
          "Amount": data?.amount == null && data?.amount == undefined ? "$ 0.00" : '$ '+formatAmount(data?.amount)+'.00',
          "Annual/Monthly": data.type,
          "Status":data.status}]}))
        })
    }).finally(() => setLoader(false))
  }

  useEffect(()=> {
    fetchData()
  },[])


  return (
    <>
    <HeaderName/>
    <div style={{display: "flex", justifyContent: 'space-between'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Payments
        </Typography>
        {excelData.bioplusNotification != '' &&
        <ExcelDownloder
        data={excelData}
        filename={'Bioplus Notification'}
        type={Type.Link} // or type={'link'}
        >
        <Button variant='outlined' style={{marginRight: 20}}>Download XLS</Button>
      </ExcelDownloder>
        }
        
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
  </>
  )
}

export default Payments