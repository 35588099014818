import { Button, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchApiData } from '../../Components/Api'
import HeaderName from '../../Components/HeaderName'
import MapComponent from '../../Components/Map'

const GeofenceMap = () => {
    const [points, setPoints] = useState([])
    const [geofenceData, setGeofenceData] = useState([])
    const navigate = useNavigate()
    const fetchData = () => {
        setPoints(prev => [])
        fetchApiData('admin/geofence/',localStorage.getItem('session')).then(data => {
            console.log({data: 'geofence',data})
            setGeofenceData(data.data.data)
            data.data.data.map(mapData => {
                let pointsArray = []
                mapData.points.coordinates[0].map(points => [
                    pointsArray.push({lat: points[1], lng: points[0]})
                ])
                setPoints(prev => prev = ([...prev, pointsArray]))
            })
            
          }).finally(() => console.log("dsdsds",points))
    }


    useEffect(()=> {
        fetchData()
    },[])
  return (
    <>
    <div style={{display: "flex", justifyContent:'space-between'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Geofence Map
        </Typography>
        <Button variant='contained' onClick={() => navigate('/geofences')} style={{marginRight: 20}}>Back</Button>
    </div>
    <div >
    {
        points.length > 0 && <MapComponent markers={points} geofenceData={geofenceData}/>
    }
         
    </div>
    </>
  )
}

export default GeofenceMap