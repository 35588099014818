import { Grid } from '@mui/material'
import React, { useState } from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import Loading from './Loading'
import NavBar from './NavBar'

const RootLayout = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false)
  return (<>
    <Grid container spacing={2} >
  <Grid item xs={2.5}>
    <NavBar activePage={location.pathname}/>
  </Grid>
  <Grid item xs={9.5} marginTop={5}>
    <Outlet context={[loader,setLoader]}/>
    </Grid>
    <Loading visible={loader}/>
    </Grid>
  </>
  )
}

export default RootLayout