import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetchApiData } from '../../Components/Api'
import EnhancedTable from '../../Components/EnhancedTable'
import HeaderName from '../../Components/HeaderName'

function createData(id,type, mode, amount, role, pic, geofence, governmentCode, isFreeGateSign, isEmergencyWarning, isVisitorLogbook, isEcvd, isEnvd, isWorkSaftey, isDesktopAccess, isSos) {
    return {
        id,
        type,
        mode,
        amount,
        role,
        pic,
        geofence,
        governmentCode,
        isFreeGateSign,
        isEmergencyWarning,
        isVisitorLogbook,
        isEcvd,
        isEnvd,
        isWorkSaftey,
        isDesktopAccess,
        isSos
    };
}

const Subscription = () => {
    const [loader, setLoader] = useOutletContext();
    const [rows, setRows] = useState([])
    // const [roles,setRoles] = useState([])
    const headCells = [
        {
            id: 'id',
            disableEdit: true,
            numeric: true,
            disablePadding: false,
            label: 'id',
        },
        {
            id: 'role',
            disableEdit: true,
            numeric: true,
            disablePadding: false,
            label: 'Role',
        },
        {
            id: 'amount',
            numeric: true,
            dataType: 'integer',
            type: 'price',
            disablePadding: false,
            label: 'Amount',
        },
        {
            id: 'type',
            type: 'select',
            collection: [{id: 'Monthly',label: 'Monthly'},{id: 'Yearly', label: 'Yearly'}],
            numeric: false,
            disablePadding: false,
            label: 'Type',
        },
        {
            id: 'mode',
            numeric: true,
            disablePadding: false,
            label: 'Mode',
        },
        {
            id: 'pic',
            numeric: true,
            disablePadding: false,
            label: 'PIC',
        },
        {
            id: 'geofence',
            numeric: true,
            disablePadding: false,
            label: 'Geofence',
        },
        {
            id: 'governmentCode',
            numeric: true,
            disablePadding: false,
            label: 'Government Code',
        },
        {
            id: 'isFreeGateSign',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'Free GateSign',
        },
        {
            id: 'isEmergencyWarning',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'Emergency Warning',
        },
        {
            id: 'isVisitorLogbook',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'Visitor Logbook',
        },
        {
            id: 'isEcvd',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'eCVD',
        },
        {
            id: 'isEnvd',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'eNVD',
        },
        
        {
            id: 'isWorkSaftey',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'Work Saftey',
        },
        {
            id: 'isDesktopAccess',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'Desktop Access',
        },
        {
            id: 'isSos',
            type: 'select',
            collection: [{id: true,label: 'Yes'},{id: false, label: 'No'}],
            dataType: 'boolean',
            numeric: true,
            disablePadding: false,
            label: 'SOS',
        },
        {
            id: 'actions',
            numeric: true,
            onlyEdit: true,
            disablePadding: false,
            label: 'Actions',
        },
    ];

    const fetchData = () => {
        setLoader(true)
        fetchApiData('admin/getSubscriptionPrices', localStorage.getItem('session')).then(data => {
            setRows([])
            console.log("data",data)
            data.data.data.map(data => {
                setRows(prev => prev = ([...prev, createData(data.id,data.paymentType, data.paymentMode, data.amount, data.role, data.pic, data.geofence,data.governmentCode === "" ? '-' : data.governmentCode , data.isFreeGateSign, data.isEmergencyWarning, data.isVisitorLogbook, data.isEcvd, data.isEnvd, data.isWorkSaftey, data.isDesktopAccess, data.isSos)]))
        })
        }).finally(() => setLoader(false))
    }

    useEffect(() => {
        // fetchApiData('records/roles/',localStorage.getItem('session')).then (roleCollection =>{ 
        //     setRoles([])
        //     Object.keys(roleCollection.data.data).map(roleData => setRoles(prevRoles => [...prevRoles,{id: roleData, label: roleData} ]))
        //   })
        fetchData()
    }, [])

    return (
        <>
            <HeaderName />
            <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                padding={1}
            >
                Subscription Plans
            </Typography>
            <div style={{ display: "flex" }}>

            </div>
            <div style={{ display: "flex" }}>
                <EnhancedTable apiId="default/" rows={rows} headCells={headCells} fetchData={fetchData} />
            </div>
        </>
    )
}

export default Subscription