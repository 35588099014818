import { Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DateField, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import withholdingPeriods from './witholding_periods.json'


const AddProductModal = ({addProductModal,toggleAddProductModal,setCvdFormData,cvdFormData}) => {
    const productList = withholdingPeriods

    const [productName, setProductName] = useState('')
    const [rate, setRate] = useState('')
    const [WHP, setWHP] = useState('')
    const [date, setDate] = useState('')
    
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        border: '1px solid black',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

    const handleAddProductSubmit = (e) => {
        e.preventDefault()
        console.log(e.target['productName'].value)
        setCvdFormData({...cvdFormData, chemicals: [...cvdFormData.chemicals, {"chemicalName": e.target['productName'].value,
        "rate": e.target['rate'].value,
        "applicationDate": e.target['applicationDate'].value,
        "WHP": e.target['whp'].value}]})
        toggleAddProductModal()
    } 

    const handleProductChange = (e) => {
        console.log(e.target.value)
        var productData = productList.find(data => data['APVMA registered product name'] == e.target.value)
        setWHP(productData['WHP (days)'])
    }
      
  
    return (
    <Modal
        open={addProductModal}
        onClose={toggleAddProductModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >

        <Box sx={{ ...modalStyle, width: 250, borderRadius: 5 }}>
        <Typography variant='h5'>Add Product</Typography>
        <br/>
        <form onSubmit={handleAddProductSubmit}>
            <FormControl style={{marginLeft: 8}} fullWidth>
    <InputLabel id="demo-simple-select-label">Product Name</InputLabel>
    <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="Product Name"
        name="productName"
        onChange={handleProductChange}
    >
        {productList.map(data => 
        <MenuItem value={data['APVMA registered product name']}>{data['APVMA registered product name']}</MenuItem>)}
    </Select>
    </FormControl>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Rate'
                  type={'number'}
                  defaultValue={rate}
                  name='rate'
                  fullWidth
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='Application Date'
                  format='DD/MM/YYYY'
                  defaultValue={dayjs(new Date())}
                  name='applicationDate'
                  fullWidth
                />
                </LocalizationProvider>
                <TextField
                  sx={{ margin: 1, color: 'black' }}
                  id="outlined-required"
                  label='WHP / ESI / EAFI'
                  value={WHP}
                  name='whp'
                  fullWidth
                />
                <div style={{display: 'flex', gap: 10, justifyContent: 'flex-end'}}>

                <Button type='submit' variant='contained'>Submit</Button>
                <Button onClick={() => toggleAddProductModal()} variant='outlined'>Cancel</Button>
                </div>
        </form>
        </Box>

      </Modal>
  )
}

export default AddProductModal