import { Button, createTheme, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { createApiData, fetchApiData } from '../../Components/Api';
import EnhancedTable from '../../Components/EnhancedTable';
import HeaderName from '../../Components/HeaderName';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactSignatureCanvas from 'react-signature-canvas';
import dayjs from 'dayjs';
import AddCvdModal from './AddCvdModal';


export const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});



function createData(id, buyerName, pic, date, propertyName, createdBy, transporter, pdfUrl) {
  return {
    id,
    buyerName,
    pic,
    date,
    propertyName,
    createdBy,
    transporter,
    pdfUrl
  };
}
const Cvd = () => {
  const [cvdModal, setCvdModal] = useState(false)
  const [loader, setLoader] = useOutletContext();
  const [rows, setRows] = useState([]);
  
  const [cvdFormData, setCvdFormData] = useState({
    "vendorName": "",
    "vendorAddress": "",
    "vendorTown": "",
    "vendorContact": "",
    "vendorFax": "",
    "vendorEmail": "",
    "vendorNGR": "",
    "vendorPIC": "",
    "vendorRefrence": "",
    "buyerName": "",
    "buyerAddress": "",
    "buyerTown": "",
    "buyerContact": "",
    "buyerFax": "",
    "buyerEmail": "",
    "buyerNGR": "",
    "buyerPIC": "",
    "buyerRefrence": "",
    "commodity": "",
    "period": dayjs(new Date()),
    "variety1": "",
    "variety2": "",
    "quantity1": 0,
    "quantity2": 0,
    "cdUnit1": "",
    "cdUnit2": "",
    "sourceCheck": "",
    "materialCheck": "",
    "gmoCheck": "",
    "chemicalCheck": "",
    "driverName": "",
    "driverEmail": "",
    "driverContact": "",
    "companyName": "",
    "registration": "",
    "organisationName": "",
    "chemicals": [
    ],
    "qaCheck": "",
    "qaProgram": "",
    "certificateNumber": "",
    "cvdCheck": "",
    "cropList": [
      
    ],
    "riskCheck": "",
    "nataCheck": "",
    "signature": "",
    "riskAssessmentResult" : '',
    "testResult" : ''

  })

  const defaultProps = {
    onChange: null,
    loadTimeOffset: 5,
    lazyRadius: 30,
    brushRadius: 12,
    brushColor: "#444",
    catenaryColor: "#0a0302",
    gridColor: "rgba(150,150,150,0.17)",
    hideGrid: false,
    canvasWidth: 400,
    canvasHeight: 800,
    disabled: false,
    imgSrc: "",
    saveData: null,
    immediateLoading: false,
    hideInterface: false,
    gridSizeX: 25,
    gridSizeY: 25,
    gridLineWidth: 0.5,
    hideGridX: false,
    hideGridY: false,
    enablePanAndZoom: false,
    mouseZoomFactor: 0.01,
    zoomExtents: { min: 0.33, max: 3 },
  };

  
  const headCells = [
    {
      id: 'id',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Serial No.',
    },
    {
      id: 'date',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'propertyName',
      numeric: true,
      disablePadding: false,
      label: 'Property Name',
    },
    {
      id: 'createdBy',
      numeric: true,
      disablePadding: false,
      label: 'Created By',
    },
    {
      id: 'buyerName',
      numeric: false,
      disablePadding: false,
      label: 'Buyer Name',
    },
    {
      id: 'pic',
      numeric: true,
      disablePadding: false,
      label: 'PIC',
    },
    {
      id: 'transporter',
      numeric: true,
      disablePadding: false,
      label: 'Transporter',
    },
    {
      id: 'pdfUrl',
      numeric: true,
      type: 'url',
      urlLabel: 'Download',
      disablePadding: false,
      label: 'View PDF',
    },
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '1px solid black',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  
  const toggleCvdModal = () => {
    setCvdModal(prev => !prev)
  }
  
  const getUserData = () => {
    setLoader(true)
    fetchApiData('users/me', localStorage.getItem('session')).then(data => {
      console.log(data)
      setCvdFormData({
        ...cvdFormData,
        vendorName: data.data.data.firstName + ' '+ data.data.data.lastName,
        vendorAddress:  data.data.data.location,
        vendorTown: data.data.data.town,
        vendorContact: data.data.data.phoneNumber,
        vendorEmail: data.data.data.email,
        organisationName: data.data.data.propertyName
      })
      
    }).finally(() => setLoader(false))
  }
  
  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/cvd', localStorage.getItem('session')).then(data => {
      setRows([])
      console.log(data)
      data.data.data.map(data => setRows(prev => prev = ([...prev, createData(data.id, data.buyerName, data.pic, data.createdAt, data.user.propertyName, data.user.firstName + ' ' + data.user.lastName, data.transporter, data.pdfUrl)])))
    }).finally(() => setLoader(false))
  }
  
  
  const cvdModalProps ={
    setCvdFormData: setCvdFormData,
    cvdFormData: cvdFormData,
    cvdModal: cvdModal,
    toggleCvdModal: toggleCvdModal,
    fetchData: fetchData,
  }
  
  
  
  
  useEffect(() => {
    getUserData()
    fetchData()
  }, [])

  
  return (
    <>
      <HeaderName />
      <div style={{ display: "flex", justifyContent: 'space-between', marginRight: 10, marginBottom: 10 }}>
        <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          CVD
        </Typography>
        <Button variant='contained' onClick={toggleCvdModal}>Add CVD</Button>
      </div>
      <div style={{ display: "flex" }}>
        <EnhancedTable rows={rows} fetchData={fetchData} apiId="admin/cvd" headCells={headCells} />
      </div>
      

      <AddCvdModal {...cvdModalProps}/>

    </>
  )
}

export default Cvd