import {Route, RouterProvider,createBrowserRouter, createRoutesFromElements} from 'react-router-dom'

import './App.css';
import RootLayout from './Components/RootLayout';
import Login from './Pages/Login/Login';
import Users from './Pages/Users/Users';
import LogRecords from './Pages/LogRecords/LogRecords';
import Notifications from './Pages/Notifications/Notifications';
import SOS from './Pages/SOS/SOS';
import Payments from './Pages/Payments/Payments';
import SignOut from './Pages/SignOut/SignOut';
import Geofences from './Pages/Geofences/Geofences';
import Cvd from './Pages/Cvd/Cvd';
import Dashboard from './Pages/Dashboard/Dashboard';
import Envd from './Pages/Envd/Envd';
import RootLayoutWOSideBar from './Components/RootLayoutWOSideBar';
import Subscription from './Pages/Subscription/Subscription';
import Attendance from './Pages/Attendance/Attendance';
import Leave from './Pages/Leave/Leave';
import GeofenceMap from './Pages/Geofences/GeofenceMap';
import GlobalPlanAddon from './Pages/GlobalPlanAddon/GlobalPlanAddon';

const router = createBrowserRouter(createRoutesFromElements(
  <>
  <Route path="/" element={<RootLayout/>}>
    <Route index element={<Dashboard/>}/>
    <Route path="/users" element={<Users/>}/>
    <Route path="/geofences" element={<Geofences/>}/>
    <Route path="/geofenceMap" element={<GeofenceMap/>}/>
    <Route path="/logrecords" element={<LogRecords/>}/>
    <Route path="/notifications" element={<Notifications/>}/>
    <Route path="/attendance" element={<Attendance/>}/>
    <Route path="/leave" element={<Leave/>}/>
    <Route path="/sos" element={<SOS/>}/>
    <Route path="/payments" element={<Payments/>}/>
    <Route path="/signout" element={<SignOut/>}/>
    <Route path="/cvd" element={<Cvd/>}/>
    <Route path="/envd" element={<Envd/>}/>
    <Route path="/subscription" element={<Subscription/>}/>
    <Route path="/globalPlanAddon" element={<GlobalPlanAddon/>}/>
  </Route>
  <Route path='/login' element={<RootLayoutWOSideBar/>}>
    <Route index element={<Login/>}/>
  </Route>
  </>
))

function App() {
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
