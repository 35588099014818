
const Base64toBlob = (base64, filename) => {
    return(new Promise((resolve, reject) => {
        const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: 'application/pdf' });
    const file = new File([blob], filename, { type: 'application/pdf' });
    resolve(file)
    }));
  }

export default Base64toBlob