import { Button, Modal, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetchApiData } from '../../Components/Api'
import EnhancedTable from '../../Components/EnhancedTable'
import HeaderName from '../../Components/HeaderName'

function createData(
    id,
    userName,
    companyName,
    role,
    qtyPremiseId,
    qtyUsers,
    qtyGeofence,
    timesheet,
    plan) {
    return {
        id,
        userName,
        companyName,
        role,
        qtyPremiseId,
        qtyUsers,
        qtyGeofence,
        timesheet,
        plan
    };
}

const GlobalPlanAddon = () => {
    const [loader, setLoader] = useOutletContext();
    const [rows, setRows] = useState([])
    const [loginModal, setLoginModal] = useState(true)
    const headCells = [
        {
            id: 'id',
            disableEdit: true,
            type: 'hidden',
            numeric: true,
            disablePadding: false,
            label: 'Id',
        },
        {
            id: 'userName',
            disableEdit: true,
            numeric: true,
            disablePadding: false,
            label: 'User Name',
        },
        {
            id: 'companyName',
            disableEdit: true,
            numeric: true,
            disablePadding: false,
            label: 'Company Name',
        },
        {
            id: 'role',
            numeric: true,
            disablePadding: false,
            label: 'Role',
        },
        {
            id: 'qtyPremiseId',
            numeric: true,
            type: 'number',
            disablePadding: false,
            label: 'Qty Premise Id',
        },
        {
            id: 'qtyUsers',
            numeric: true,
            type: 'number',
            disablePadding: false,
            label: 'Qty Users',
        },
        {
            id: 'qtyGeofence',
            numeric: true,
            type: 'number',
            disablePadding: false,
            label: 'Qty Geofence',
        },
        {
            id: 'timesheet',
            numeric: true,
            disablePadding: false,
            label: 'Time Sheet',
        },
        {
            id: 'actions',
            numeric: true,
            onlyEdit: true,
            disablePadding: false,
            label: 'Actions',
        },
    ];

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'white',
        boxShadow: '0 0 15px 2px grey',
        pt: 2,
        px: 4,
        pb: 3,
      };

    const fetchData = () => {
        setLoader(true)
        axios.get('https://service-dev.bioplusglobal.io/subscription-plans',{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('usToken')}`,
            },
        }).then(data => {
            setRows([])
            console.log("data",data)
            data.data.items.map(data => {
                setRows(prev => prev = ([...prev, createData(data.id,'', data.id, data.name, data.features.maxPremises.value, data.features.maxUsers?.value, data.features.maxGeofences.value, data.features.timesheet.type)]))
        })
            console.log(rows)
        }).catch(err => {
            if(err.response.status === 401){
                localStorage.removeItem('usToken')
                setLoginModal(true)
            }
        }).finally(() => setLoader(false))
    }

    const loginFormSubmit = (event) => {
        event.preventDefault();
        axios.post('https://service-dev.bioplusglobal.io/auth/login',
        {
            "email": event.target['email'].value,
            "password": event.target['password'].value
        })
        .then((data) => {
            alert('Login Successfully')
            console.log(data)
            localStorage.setItem('usToken',data.data.accessToken)
            setLoginModal(false)
            fetchData()
        })

        console.log(event)
    }

    const onUpdateSubmit = (event,setOpenModal) => {

        console.log(event)

        axios.put('https://service-dev.bioplusglobal.io/subscription-plans/'+event.target['companyId'].value ,{
            "name": event.target['role'].value,
            "features": {
              "maxPremises": {
                "value": event.target['qtyPremiseId'].value
              },
              "maxGeofences": {
                "value": event.target['qtyGeofence'].value
              },
              "maxUsers": {
                "value": event.target['qtyUsers'].value,
              },
              "timesheet": {
                "type": event.target['timesheet'].value
              }
            }
          },{
            headers: {
              Authorization: `Bearer ${localStorage.getItem('usToken')}`,
            },
        }).then(data => {
            console.log(data)
            alert('Updated Successfully')
            fetchData()
            setOpenModal(false)
        })
    }

    useEffect(() => {
        if(localStorage.getItem('usToken')){
            setLoginModal(false)
        }
        // fetchApiData('records/roles/',localStorage.getItem('session')).then (roleCollection =>{ 
        //     setRoles([])
        //     Object.keys(roleCollection.data.data).map(roleData => setRoles(prevRoles => [...prevRoles,{id: roleData, label: roleData} ]))
        //   })
        fetchData()
    }, [])

    return (
        <>
            <HeaderName />
            <Typography
                variant="h5"
                id="tableTitle"
                component="div"
                padding={1}
            >
                Global Plan Addon
            </Typography>
            <div style={{ display: "flex" }}>

            </div>
            <div style={{ display: "flex" }}>
                <EnhancedTable rows={rows} headCells={headCells} customSubmit={onUpdateSubmit} />
            </div>

            <Modal
        hideBackdrop
        open={loginModal}
        onClose={() => setLoginModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form onSubmit={(event) => loginFormSubmit(event)}>
          <Box sx={{height: 250, marginBottom: 2, alignItems: 'space-between'}}>
        
          <h2 id="child-modal-title">Login</h2>
          <TextField
            label='Email ID'
            variant='outlined'
            name='email'
            fullWidth
            style={{marginTop: '20px'}}
          />
          <TextField
            label='Password'
            variant='outlined'
            name='password'
            type='password'
            fullWidth
            style={{marginTop: '20px'}}
          />
          </Box>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={() => setLoginModal(false)}>Close</Button>
          <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>
          
        </div>
        </form>
        </Box>
      </Modal>

        </>
    )
}

export default GlobalPlanAddon