import axios from 'axios';

const client = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_PROD_URL
      : process.env.REACT_APP_DEV_URL,
});

client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default client;
