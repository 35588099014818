import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import { Autocomplete, Box, Button, Chip, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { createApiData, fetchApiData, updateApiData } from '../../Components/Api';
import { useExcelDownloder } from '@coinsummer/react-xls';
import { useNavigate, useOutletContext } from 'react-router-dom';
import formatAmount from '../../Components/formatAmount';


function createData(id, firstName, lastName, email, company, employerCompany, role, phoneNumber, registeredDate, subscription,trialStatus, amount, status, stripeId) {
  return {
    id,
    firstName,
    lastName,
    email,
    company,
    employerCompany,
    role,
    phoneNumber,
    registeredDate,
    subscription,
    trialStatus,
    amount,
    status,
    stripeId
  };
}


const Users = () => {
  const [loader,setLoader] = useOutletContext();
  const [rows,setRows] = useState([]);
  const { ExcelDownloder, Type } = useExcelDownloder();
  const [roles, setRoles] = useState([])
  const [openModal,setOpenModal] = useState(false)
  const [userIdPayment,setUserIdPayment] = useState('')
  const [stripesIdPayment,setStripesIdPayment] = useState('')
  const [userCompanyPayment,setUserCompanyPayment] = useState([])
  const [companies,setCompanies] = useState([])
  const [picList,setPicList] = useState([])
  const [openUserModal,setOpenUserModal] = useState(false)

  const navigate = useNavigate();
  const headCells = [
    {
      id: 'id',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'email',
      numeric: true,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'company',
      type: 'multiSelectTag',
      freeSolo: true,
      numeric: true,
      collection: companies,
      disablePadding: false,
      label: 'Company',
    },
    {
      id: 'employerCompany',
      numeric: true,
      disablePadding: false,
      label: 'Employer Company',
    },
    // {
    //   id: 'allowedRoles',
    //   type: 'multiSelectTag',
    //   numeric: true,
    //   collection: ['Admin', 'Business', 'Visitor', 'Property', 'Producer', 'Contractor'],
    //   disablePadding: false,
    //   label: 'Allowed Roles',
    // },
    {
      id: 'role',
      numeric: true,
      type: 'select',
      collection: roles,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'phoneNumber',
      type:'phoneNumber',
      numeric: true,
      disablePadding: false,
      label: 'Mobile Number',
    },
    {
      id: 'registeredDate',
      type: 'date',
      disableEdit: true,
      numeric: true,
      disablePadding: false,
      label: 'Registered Date',
    },
    {
      id: 'subscription',
      numeric: true,
      disableEdit: true,
      disablePadding: false,
      label: 'Subscription',
    },
    {
      id: 'trialStatus',
      numeric: true,
      disablePadding: false,
      label: 'Trial Status',
    },
    {
      id: 'amount',
      numeric: true,
      disableEdit: true,
      disablePadding: false,
      label: 'Amount',
    },
    {
      id: 'status',
      numeric: true,
      type: 'select',
      collection: [{id: 'Active',label: 'Active'},{id: 'Inactive', label: 'InActive'}],
      disablePadding: false,
      label: 'Account Status',
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ];
  const [exceldata,setExcelData] = useState({
    bioplusUsers: [
    ],
  });

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const toggleModal = () => {
    setOpenModal(prev => prev = !prev)
  }
  
  const toggleUserModal = () => {
    setOpenUserModal(prev => prev = !prev)
  }

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/company',localStorage.getItem('session')).then(data => setCompanies(data.data.data))
    fetchApiData('admin/user/',localStorage.getItem('session')).then(data => {
      console.log(data)
      
      setRows([])
      setExcelData({
        bioplusUsers: [
        ],
      })
      fetchApiData('records/roles/',localStorage.getItem('session')).then (roleCollection =>{ 
        setRoles([])
        console.log('dsds',roleCollection.data.data)
        Object.keys(roleCollection.data.data).map(roleData => setRoles(prevRoles => [...prevRoles,{id: roleData, label: roleData} ]))
      })
      data.data.data.users.map(async data => {
        setRows(prev => prev = ([...prev, createData(data.id,data.firstName,data.lastName,data.email,data.company !== null ? data.company : [], data.employerCompany,data.role,{countryCode: data.countryCode, number: data.phoneNumber},data.createdAt,data.isSubscribed === true ? 'Yes': 'No',data.trialStatus,data.paymentSchemaId?.amount == null && data.paymentSchemaId?.amount == undefined ? "$ 0.00" : '$ '+formatAmount(data.paymentSchemaId?.amount)+'.00' ,data.status,data.stripeCusId)]))
        setExcelData(prev => prev = ({...prev,bioplusUsers: [...prev.bioplusUsers,{"Full Name": data.firstName+ " "+data.lastName, "Email Id": data.email,"Role": data.role, "Mobile Number": data.countryCode+data.phoneNumber,"Company": data.company?.join(','),"Employer Company": data.employerCompany,"Subscribed": data.isSubscribed === true ? 'Yes': 'No',"Registered Date": data.createdAt,'Trial Status': data.trialStatus,'Amount': data.paymentSchemaId?.amount == null && data.paymentSchemaId?.amount == undefined ? "$ 0.00" : '$ '+formatAmount(data.paymentSchemaId?.amount)+'.00','Status': data.status}]}) )
        return ''
      })
      setLoader(false)
    })
  }

  const updateRoles = (event,tempData) => {
    let userDetails = tempData.find(data => data.id == event.target['id'].value)
    console.log(userDetails)
    updateApiData('users/',event.target['id'].value,'',localStorage.getItem('session')).then(userData => {
    switch(event.target['role'].value){
      case 'Admin':
          if(userData.data.data.role !== 'Admin'){
            updateApiData('admin/makeAdmin/',event.target['id'].value,'',localStorage.getItem('session')).then(data => console.log(data))
            fetchData()
          }
        break;
      case 'Corporate': {
        console.log(userDetails['role'],'dsds')
        if(userData.data.data.role !== 'Corporate'){
          setUserIdPayment(event.target['id'].value)
          setStripesIdPayment(rows[rows.findIndex(data => data.id == event.target['id'].value)].stripeId)
          toggleModal()
        }
        break;
      }
      default : 
        console.log('Nothing')
        break;
    }
  
  
  }).catch(err => console.log(err))
  }

  const handlePaymentSubscription = (event) => {
    event.preventDefault()
    if(event.target['amount'].value === ''){
      alert('Enter some Amount');
    }else if(userCompanyPayment.length === 0){
      alert('Enter Atleast 1 company');
    }else{
      createApiData('payment/create-subscription',{
        "amount": event.target['amount'].value,
      "company": userCompanyPayment,
      "interval": event.target['interval'].value,
      "userId": event.target['userId'].value,
      "stripeCusId": event.target['stripeCusId'].value
      },localStorage.getItem('session')).then(data => {
        console.log("payment subscription",data)
        alert('Payment Subscription Added')
        toggleModal()
      })

    }

    // updateApiData('admin/makeCorporate/',event.target['id'].value,'',localStorage.getItem('session'))
    //     .then(data => {
    //     }).finally(console.log({stripesIdPayment}))
    console.log({event,userCompanyPayment})
  }

  const handleAddUser = (event) => {
    event.preventDefault()
    if(event.target['email'].value === ''){
      alert('Email cannot be empty');
    }else if(event.target['email'].value.match('/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/') == false){
      alert('Enter Email Id')
    }else if(event.target['role'].value === ''){
      alert('select a role')
    }else if(localStorage.getItem('role') === "Corporate" && event.target['pic']?.value === ''){
      alert('Enter a PIC');
    }else{
      let body = {}
      if(localStorage.getItem('role') === "Corporate"){
        body = {
          "email": event.target['email'].value,
        "pic": parseInt(event.target['pic'].value),
        "role": event.target['role'].value
        }
      }else{
        body = {
          "email": event.target['email'].value,
        "role": event.target['role'].value
        }
      }
      createApiData('admin/createUser',body,localStorage.getItem('session')).then(data => {
        console.log("User Added",data)
        alert('New User Added')
        toggleUserModal()
      }).catch(err => console.log(err))

    }
  }
  

  useEffect(()=> {
    // if(localStorage.getItem('role') !== 'Admin' && localStorage.getItem('role') !== 'Business'){
    //   navigate('/')
    // }

    fetchApiData('users/getAllPic',localStorage.getItem('session')).then(data => setPicList(data.data.data))
    console.log(picList)
    fetchData()
  },[])

  return (
    <>
  <HeaderName/>
  <div style={{display: 'flex', justifyContent: 'space-between', width: '99%'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
          >
          Users
        </Typography>
        <div>

        {exceldata.bioplusUsers != '' &&
        <ExcelDownloder
        data={exceldata}
        filename={'Bioplus Users'}
        type={Type.Link} // or type={'link'}
        >
        <Button variant='outlined' style={{marginRight: 20}}>Download XLS</Button>
      </ExcelDownloder>
        }
        {localStorage.getItem('role') !== 'Admin'&&<Button variant='contained' onClick={toggleUserModal}>Add User</Button>}
        </div>
        </div>
    <div style={{display: "flex"}}>
      <EnhancedTable apiId="users/" fetchData={fetchData} rows={rows} setRows={setRows} headCells={headCells} appendSubmit={updateRoles}/>
    </div>
    <Modal
        hideBackdrop
        open={openModal}
        onClose={toggleModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form onSubmit={handlePaymentSubscription}>
          <Box sx={{height: 500, overflow: 'scroll',overflowX: 'hidden', marginBottom: 2}}>
        
          <h2 id="child-modal-title">Payments</h2>
          
        <TextField
          sx={{margin: 1,color: 'black'}}
          id="outlined-required"
          label='User Id'
          defaultValue={userIdPayment}
          name='userId'
          inputProps={
            { readOnly: true }
          }
        />
        <TextField
          sx={{margin: 1,color: 'black'}}
          id="outlined-required"
          label='Stripe Id'
          name='stripeCusId'
          defaultValue={stripesIdPayment}
          inputProps={
            { readOnly: true }
          }
        />
          <TextField
          sx={{margin: 1,color: 'black'}}
          id="outlined-required"
          label='Amount'
          name='amount'
          type='number'
        />
        <FormControl >
          <InputLabel id="select-label">Interval</InputLabel>
          <Select
            sx={{margin: 1, minWidth: '223px'}}
            labelId="select-label"
            id="select-createdBy"
            name='interval'
            defaultValue='Yearly'
          >
              <MenuItem value='Yearly'>Yearly</MenuItem>
            
          </Select>
          </FormControl>
        <FormControl >
          <Autocomplete
            multiple
            freeSolo
            id="tags-filled"
            options={companies}
            defaultValue={[]}
            name='company'
            onChange= {(value,collection)=> {value && setUserCompanyPayment(collection)}}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <>
                {option !== '' && <Chip name='company' variant="outlined" label={option} {...getTagProps({ index })} />}
                </>
              )
              )
            }
            renderInput={(params) => (
              <>
              <TextField
                {...params}
                label='Enter Company'
                sx={{margin: 1, width: 460}}
                placeholder='Enter Company'
              />
              {/* <input
              name={cell.id}
              value={multiTagValue}
              hidden={true}
              /> */}
              </>
            )}
          />
        </FormControl>
          

          </Box>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={toggleModal}>Close</Button>
          <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>
          
        </div>
        </form>
        </Box>
      </Modal>

      <Modal
        hideBackdrop
        open={openUserModal}
        onClose={toggleUserModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        
        <Box sx={{...modalStyle, width: 500, borderRadius: 5 }}>
        <form onSubmit={handleAddUser}>
          <Box sx={{height: 200, marginBottom: 2}}>
        
          <h2 id="child-modal-title">Add New User</h2>
          
        <TextField
          sx={{margin: 1,color: 'black'}}
          id="outlined-required"
          label='Email'
          name='email'
        />
        
        { localStorage.getItem('role') === "Corporate" ?
        <>

        <FormControl >
        <InputLabel id="select-role-label">Role</InputLabel>
        <Select
                            sx={{margin: 1}}
                            labelId="select-role-label"
                            id="role"
                            name='role'
                            placeholder='Select a company'
                            style={{width: 220}}
                          >
                            <MenuItem value="Manager">Manager</MenuItem>
                            
                          </Select>
        
        </FormControl>
        <FormControl >
        <InputLabel id="select-company-label">PIC</InputLabel>
        <Select
                            sx={{margin: 1}}
                            labelId="select-pic-label"
                            id="pic"
                            name='pic'
                            placeholder='Select PIC'
                            style={{width: 460}}
                          >
                            {picList.length > 0 && picList.map((data,index) => <MenuItem key={index} value={data.id}>{data.pic}</MenuItem>)}
                            
                          </Select>
        
        </FormControl>
        </>: 
        <FormControl >
        <InputLabel id="select-role-label">Role</InputLabel>
        <Select
                            sx={{margin: 1}}
                            labelId="select-role-label"
                            id="role"
                            name='role'
                            placeholder='Select a company'
                            style={{width: 220}}
                          >
                            <MenuItem value="Employee">Employee</MenuItem>
                            
                          </Select>
        
        </FormControl>
        }
          

          </Box>
          
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button variant="outlined" onClick={toggleUserModal}>Close</Button>
          <Button type='submit' variant="contained" sx={{marginLeft: 2}}>Submit</Button>
          
        </div>
        </form>
        </Box>
      </Modal>  
  </>
  )
}

export default Users