import React, { useEffect, useState } from 'react'
import EnhancedTable from '../../Components/EnhancedTable';

import {  Button, Typography } from '@mui/material';
import HeaderName from '../../Components/HeaderName';
import { fetchApiData } from '../../Components/Api';
import { useNavigate, useOutletContext } from 'react-router-dom';


function createData(id, name, registered, unregistered, companyOwner,  createdBy, locations) {
  return {
    id,
    name, 
    registered, 
    unregistered,
    companyOwner,
    createdBy,
    locations
  };
}

const AnyReactComponent = ({ text }) => <div>{text}</div>;


const Geofences = () => {
  const [loader,setLoader] = useOutletContext();
  const [rows,setRows] = useState([]);
  const navigate = useNavigate()
  const [userCollection, setUserCollection] = useState([]);
  const headCells = [
    {
      id: 'id',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Id',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Geofence Name',
    },
    {
      id: 'registered',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Occupied Registered',
    },
    {
      id: 'unregistered',
      numeric: false,
      disableEdit: true,
      disablePadding: false,
      label: 'Occupied Unregistered',
    },
    {
      id: 'companyOwner',
      numeric: false,
      disablePadding: false,
      label: 'Company Owner Name',
    },
    {
      id: 'createdBy',
      collection: userCollection,
      numeric: true,
      disableEdit: true,
      disablePadding: false,
      label: 'Creator Name',
    },
    {
      id: 'actions',
      // includeMap: true, 
      numeric: true,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    fetchApiData('admin/user',localStorage.getItem('session')).then(data => {
      setUserCollection([])
      console.log(data)
      data.data.data.users.map((data,index) => (
        setUserCollection(prev => prev = ([...prev,{id : data.id, label: data.firstName+" "+data.lastName}]))
      ))
    })
    fetchApiData('admin/geofence/',localStorage.getItem('session')).then(data => {
      console.log({data: 'geofence',data})
      setRows([])
      data.data.data.map(data => setRows(prev => prev = ([...prev, createData(data.id,data.name,data.registered !== null ? data.registered : 'null', data.unregistered !== null ? data.unregistered : 'null', data.companyOwner,data.createdBy.firstName+' '+data.createdBy.lastName, data.points)])))
    }).finally(() => setLoader(false))
  }

  useEffect(()=> {
    fetchData()
  },[])
  return (
    <>
  <HeaderName/>
  <div style={{display: "flex", justifyContent: "space-between", marginBottom: '10px'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
          Geofence
        </Typography>
    
        <Button variant='contained' onClick={() => navigate('/geofenceMap')} style={{marginRight: 20}}>View Map</Button>
        
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} fetchData={fetchData} apiId="geofences/" headCells={headCells}/>
    </div>
  </>
  )
}

export default Geofences