import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { createApiData, fetchApiData } from './Api';


const HeaderName = () => {
  const [logo,setLogo] = useState(null)
  const style = {display: "flex", 
  justifyContent: "center", 
  alignItems: "center",
  height: 120,
  width: 120, 
  borderRadius: 100, 
  backgroundColor: "#E1F6FF",
  m: 20,
  cursor: 'pointer',
  color: "#ADD8E6"}
  const logoStyle = {
    maxHeight: 120,
    maxWidth: 120,
    borderRadius: 100, 
  }

  const updateImage = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const data = new FormData();
    data.append('photo', file, file.name);

    createApiData('admin/updateLogo',data,localStorage.getItem('session'),{
      headers: {
        'Content-Type': `multipart/form-data;boundary=${data._boundary}`,
      },
      timeout: 30000,
    })
    .then(() => {fetchLogo(); alert("Logo Updated Successfully")})
    .catch(err => alert(err))
  }

  const fetchLogo = () => {
    fetchApiData('users/me',localStorage.getItem('session'))
    .then(data => setLogo(prev => prev = data.data.data.logo))
  }
    
  useEffect(() => {
    fetchLogo()
  },[])

  return (
    <div style={{display: "flex", flexDirection: "row",alignItems: "center"}}>
      <div onClick={() => document.getElementById('file-input').click()} style={style}>
      {logo === null ?
        <CameraAltOutlinedIcon/>
        : <img style={logoStyle}src={logo}/>
    }
    </div>
    <input id="file-input" type="file" name="name" onChange={updateImage} accept="image/png, image/jpeg"  hidden/>
      <Typography
          variant="h4"
          id="tableTitle"
          component="div"
          fontWeight="bold"
          paddingLeft={5}
        >
          {localStorage.getItem('role') === 'Admin' ? 'BIOPLUS - Admin Panel' : localStorage.getItem('role') === 'Corporate' ? 'BIOPLUS - Corporate Panel' : 'BIOPLUS - Manager Panel'}
        </Typography>
    </div>
  )
}

export default HeaderName