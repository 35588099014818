import { Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { fetchApiData } from '../../Components/Api'
import EnhancedTable from '../../Components/EnhancedTable'
import HeaderName from '../../Components/HeaderName'

function createData(name, checkIn, checkOut, createdAt) {
    return {
        name,
        checkIn,
        checkOut,
        createdAt
    };
  }

const Attendance = () => {
    const [loader,setLoader] = useOutletContext();
  const [rows, setRows] = useState([])
  
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'checkIn',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Check In',
    },
    {
      id: 'checkOut',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Check Out',
    },
    {
      id: 'createdAt',
      type: 'date',
      numeric: true,
      disablePadding: false,
      label: 'Created At',
    },
  ];

  const fetchData = () => {
    setLoader(true)
    setRows([])
    fetchApiData('records/getAttendance',localStorage.getItem('session')).then(resp => {
        resp.data.data.map(data => {
            setRows(prev => [...prev , createData(data.user.firstName+' '+data.user.lastName,data.checkIn,data.checkOut,data.createdDate)])
        })
        console.log(resp)
    }).finally(() => setLoader(false))
  }

  useEffect(() => {
    fetchData()
  },[])
  return (
    <>
  <HeaderName/>
    <div style={{display: "flex", justifyContent:'space-between'}}>
    <Typography
          variant="h5"
          id="tableTitle"
          component="div"
          padding={1}
        >
        Attendance
    </Typography>
    </div>
    <div style={{display: "flex"}}>
      <EnhancedTable rows={rows} headCells={headCells}/>
    </div>
    </>
  )
}

export default Attendance