import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import mainLogo from '../../assets/logo.png'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { createApiData } from '../../Components/Api';

function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="">
          BIOPLUS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Login = () => {
    const [loader,setLoader] = useOutletContext();
    const navigate = useNavigate();
    if(localStorage.getItem('session') !== null){
      navigate('/users')
    }

    const handleSubmit = (event) => {
      setLoader(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
          email: data.get('email'),
          password: data.get('password'),
        });

        createApiData('admin/login',{
          email: data.get('email'),
          password: data.get('password'),
        }).then(data => {
          if(data.data.data.user.role !== 'Corporate' && data.data.data.user.role !== 'Admin' && data.data.data.user.role !== 'Manager'){
            alert('User role not Allowed!')
            return;
          }
          
          localStorage.setItem('session',data.data.token);
          localStorage.setItem('userName',data.data.data.user.firstName+" "+data.data.data.user.lastName);
          localStorage.setItem('role',data.data.data.user.role);
        }).catch(err => {
          alert(err.response.data.message)})
        .finally(() => {setLoader(false);navigate('/')})
        
      };

      useEffect(()=> {
        if(localStorage.getItem('session'))
        navigate('/')
      },[])
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url('+mainLogo+')',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#3E4993',
            backgroundSize: 'none',
            backgroundPosition: 'center',
          }}
        >
            <Typography component="h1" variant="h4" sx={{
                color: "#75BA51",
                fontWeight: "bold",
                fontSize: 50,
                height: '30%',
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                
                }}>
              BIOPLUS
            </Typography>
            </Grid>

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              height: '80vh',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h4">
              Log In
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#3E4993' }}
              >
                Sign In
              </Button>
              
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
  )
}

export default Login