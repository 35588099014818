import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Loading from './Loading'

const RootLayoutWOSideBar = () => {
  const[loader,setLoader] = useState(false)
  return (
    <>
    <Loading visible={loader}/>
    <Outlet context={[loader,setLoader]}/>
    </>
    
  )
}

export default RootLayoutWOSideBar