import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SignOut = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('session')
    navigate('/login')
  },[navigate])
  return (
    <div>SignOut</div>
  )
}

export default SignOut