    import React, { useEffect, useRef, useState } from 'react';
    import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
    import { Button, Card, CardActions, CardContent, TextField, Typography } from '@mui/material';

    import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
    import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
    import CancelIcon from '@mui/icons-material/Cancel';
    import AddIcon from '@mui/icons-material/Add';
    import ClearIcon from '@mui/icons-material/Clear';

    import { createApiData, deleteApiData, updateApiData } from './Api';

    const libraries = ['places', 'drawing'];
    const MapComponent = ({markers,geofenceData}) => {
        const mapRef = useRef();
        const polygonRefs = useRef([]);
        const activePolygonIndex = useRef();
        const autocompleteRef = useRef();
        const drawingManagerRef = useRef();
        const [activeId, setActiveId] = useState('')
        const [addGeofenceModal, setAddGeofenceModal] = useState(false)
        const [activeCoordinates, setActiveCoordinates] = useState()
        const [tempCoordinates, setTempCoordinates] = useState()
        const [polygons, setPolygons] = useState(markers);
        const [drawingMode, setDrawingMode] = useState('');
        const [activePolygon, setActivePolygon] = useState();
        const [picError, setPicError] = useState(false);


        const defaultCenter = markers[0][0]
        const [center, setCenter] = useState(defaultCenter);
        const [geofenceDetail,setGeofenceDetail] = useState({
            index : '',
            id: '',
            name: '',
            area: '',
            pic: '',
            createdby: '',
            companyOwner: '',
            declarationForm: ''
        })
        const [geofenceTempData,setGeofenceTempData] = useState({
            index : '',
            id: '',
            name: '',
            area: '',
            pic: '',
            createdby: '',
            companyOwner: '',
            declarationForm: ''
        })
        
        var loadedPolygons = []
        
        const { isLoaded, loadError } = useJsApiLoader({
            googleMapsApiKey: 'AIzaSyB1KCluOXIh5ipV4wcHe3z-ottrOfel1As',
            libraries
        });
        

        const containerStyle = {
            marginTop: '10px',
            borderRadius: '5px',
            boxShadow: '0 0 5px 2px grey',
            width: '99%',
            height: '85vh',
        }

        const autocompleteStyle = {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '15%',
            height: '36px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '30%',
            top: '10px',
            marginLeft: '5px',
        }

        const detailsCardStyle = {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '180px',
            height: 'auto',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '5px',
            top: '70px',
            marginLeft: '5px',
        }

        const editGeofenceStyle = {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '180px',
            height: '250px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '5px',
            top: '330px',
            marginLeft: '5px',
        }

        const addGeofenceCardStyle = {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '200px',
            height: '280px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            position: 'absolute',
            left: '5px',
            top: '70px',
            marginLeft: '5px',
        }

        const addGeofenceStyle = {
            cursor: 'pointer',  
            height: '36px',
            width: '100px',
            paddingTop: '5px',
            borderRadius: '2px',
            marginTop: '5px', 
            textTransform: 'none',
            position: 'absolute',
            top: "5px",
            left: "45%",
            marginLeft: '15px'
        }



        const polygonOptions = (index) => {
            if(geofenceData[index] === undefined){
                console.log('sjs')
                return(
                    {fillOpacity: 0.3,
                    fillColor: '#ff0000',
                    strokeColor: '#ff0000',
                    strokeWeight: 2,}
            ) 
            }else{
                return(
                    {fillOpacity: 0.3,
                    fillColor: '#'+geofenceData[index].color,
                    strokeColor: '#'+geofenceData[index].color,
                    strokeWeight: 2,}
            ) 
            }
        
        }


        const drawingManagerOptions = {
            polygonOptions: polygonOptions,
            drawingControl: false,
            drawingControlOptions: {
                position: window.google?.maps?.ControlPosition?.TOP_LEFT,
                drawingModes: [
                    window.google?.maps?.drawing?.OverlayType?.POLYGON
                ],
            },
        }

        const onLoadMap = (map) => {
            mapRef.current = map;
        }

        const onLoadPolygon = (polygon, index) => {
            console.log(loadedPolygons.findIndex(value => value == index),' foundt',index)
            // if(loadedPolygons.findIndex(value => value == index) === -1){
                loadedPolygons.push(index)
                console.log('B',polygon,index)
                polygonRefs.current[index] = polygon;
            // }   
        }

        const onClickPolygon = (index) => {
            console.log('change',geofenceData)
            if(activeId !== index){
                polygons[activeId] = tempCoordinates
                setActiveId('')
            }

            setGeofenceDetail({
                index: index,
                id: geofenceData[index].id,
                name: geofenceData[index].name,
                area: Math.round(calcArea(geofenceData[index].points.coordinates[0]) * 100) / 100,
                pic: geofenceData[index].pic,
                createdby: geofenceData[index].createdBy.firstName + ' '+ geofenceData[index].createdBy.lastName,
                companyOwner: geofenceData[index].companyOwner,
                declarationForm: geofenceData[index].isDeclarationNeeded ? 'Yes' : 'No',
            })
            setGeofenceTempData({
                index: index,
                id: geofenceData[index].id,
                name: geofenceData[index].name,
                area: Math.round(calcArea(geofenceData[index].points.coordinates[0]) * 100) / 100,
                pic: geofenceData[index].pic,
                createdby: geofenceData[index].createdBy.firstName + ' '+ geofenceData[index].createdBy.lastName,
                companyOwner: geofenceData[index].companyOwner,
                declarationForm: geofenceData[index].isDeclarationNeeded ? 'Yes' : 'No',
            })
            console.log(activePolygonIndex)
            activePolygonIndex.current = index; 
        }

        const onLoadAutocomplete = (autocomplete) => {
            autocompleteRef.current = autocomplete;
        }

        const onPlaceChanged = () => {
            const { geometry } = autocompleteRef.current.getPlace();
            const bounds = new window.google.maps.LatLngBounds();
            if (geometry.viewport) {
                bounds.union(geometry.viewport);
            } else {
                bounds.extend(geometry.location);
            }
            mapRef.current.fitBounds(bounds);
        }

        const onLoadDrawingManager = drawingManager => {
            drawingManagerRef.current = drawingManager;
        }

        const onOverlayComplete = ($overlayEvent) => {
            console.log('drawing manager')
            drawingManagerRef.current.setDrawingMode(null);
            if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
                const newPolygon = $overlayEvent.overlay.getPath()
                    .getArray()
                    .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))

                // start and end point should be same for valid geojson
                const startPoint = newPolygon[0];
                newPolygon.push(startPoint);
                $overlayEvent.overlay?.setMap(null);
                setPolygons([...polygons, newPolygon]);
            }
        }

        const onPolygonComplete = (polygon) => {
            console.log("polygoncomplete", polygon.getPath(), polygons);
            let coordinates = polygon.getPath()
            .getArray()
            .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))
            setActivePolygon(coordinates)
            setAddGeofenceModal(true)
        };

        const onDeleteDrawing = (deleteIndex) => {  
            console.log(activePolygonIndex,'  ',deleteIndex,' kfdl')
            // const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current) 
            if(deleteIndex){
            setPolygons(polygons.filter((data, index) => index !== deleteIndex ))
            }else{
                setPolygons(polygons.slice(0, -1))

            }
        }

        const onEditPolygon = (index) => {
            const polygonRef = polygonRefs.current[index];
            if (polygonRef) {
                const coordinates = polygonRef.getPath()
                    .getArray()
                    .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

                const allPolygons = [...polygons];
                allPolygons[index] = coordinates;
                setPolygons(allPolygons)
                setActiveCoordinates(coordinates)
                console.log('edit',coordinates)
            }
        }

        function calcArea(locations) {
            if (!locations.length) {    
                return 0;
            }
            if (locations.length < 3) {
                return 0;
            }
            let radius = 6371000;
        
            const diameter = radius * 2;
            const circumference = diameter * Math.PI;
            const listY = [];
            const listX = [];
            const listArea = [];
            // calculate segment x and y in degrees for each point
        
            const latitudeRef = locations[0][1];
            const longitudeRef = locations[0][0];
            for (let i = 1; i < locations.length; i++) {
            let latitude = locations[i][1];
            let longitude = locations[i][0];
            listY.push(calculateYSegment(latitudeRef, latitude, circumference));
        
            listX.push(calculateXSegment(longitudeRef, longitude, latitude, circumference));
        
            }
        
            // calculate areas for each triangle segment
            for (let i = 1; i < listX.length; i++) {
            let x1 = listX[i - 1];
            let y1 = listY[i - 1];
            let x2 = listX[i];
            let y2 = listY[i];
            listArea.push(calculateAreaInSquareMeters(x1, x2, y1, y2));
        
            }
        
            // sum areas of all triangle segments
            let areasSum = 0;
            listArea.forEach(area => areasSum = areasSum + area)
        
        
            // get abolute value of area, it can't be negative
            let areaCalc = Math.abs(areasSum);// Math.sqrt(areasSum * areasSum);  
            return areaCalc;
        }
        
        function calculateAreaInSquareMeters(x1, x2, y1, y2) {
            return (y1 * x2 - x1 * y2) / 2;
        }
        
        function calculateYSegment(latitudeRef, latitude, circumference) {
            return (latitude - latitudeRef) * circumference / 360.0;
        }
        
        function calculateXSegment(longitudeRef, longitude, latitude, circumference)     {
            return (longitude - longitudeRef) * circumference * Math.cos((latitude * (Math.PI / 180))) / 360.0;
        }

        // Geofence Operations

        function editGeofence(index){
            setActiveId(geofenceDetail.index)
            setTempCoordinates(polygons[geofenceDetail.index])
            console.log('edit',index)
        }

        function toggleDrawingMode(){
            if(drawingMode !== 'polygon'){
                setDrawingMode('polygon')
                setGeofenceDetail({
                    index : '',
                    id: '',
                    name: '',
                    area: '',
                    pic: '',
                    createdby: '',
                    companyOwner: '',
                    declarationForm: ''
                })
            }else{
                setDrawingMode('')
            }
        }

        function addGeofence (id,event) {
            event.preventDefault()
            if(event.target['name'].value.length < 1){
                alert('Field Name cannot be Empty')
                return
            }
            if(event.target['companyOwner'].value.length < 1){
                alert('Company Owner cannot be Empty')
                return
            }
            if(event.target['pic'].value.length !== 8){
                alert('PIC should be 8 Characters')
                return
            }
            let geoPoints = []
            activePolygon.map(data => geoPoints.push([data.lng,data.lat]))
            console.log({event, activePolygon, geoPoints})
            
            createApiData('geofences/',{
                color: 'ff2196f3',
                name: event.target['name'].value,
                pic: event.target['pic'].value.toUpperCase(),
                companyOwner: event.target['companyOwner'].value,
                points: geoPoints
            }, localStorage.getItem('session')).then(data => {
                console.log(data.data.data)
                alert('Created Geofence Successfully')
                geofenceData.push(data.data.data)
                console.log(geofenceData)
                setAddGeofenceModal(false)
                setDrawingMode('')
                // fetchData()
            })
        }

        function deleteGeofence(index){
            console.log('delete',index)
            let geofenceIndex = geofenceData.findIndex(value => value.id === index)
            if(window.confirm("Delete "+geofenceData[geofenceIndex].name+"?")){
                deleteApiData('geofences/',index,localStorage.getItem('session'))
                .then(data => {
                    alert('Geofence Deleted Successfully')
                    let indexValue = geofenceData.indexOf(index);
                    geofenceData.splice(geofenceIndex, 1);
                    setGeofenceDetail({
                        index : '',
                        id: '',
                        name: '',
                        area: '',
                        pic: '',
                        createdby: '',
                        companyOwner: '',
                        declarationForm: ''
                    })
                    onDeleteDrawing(geofenceIndex)
                    // fetchData()
                })
                .catch(err => console.log(err))
            }
        }

        function saveGeofence(index,event){
            event.preventDefault()
            if(event.target['name'].value.length < 1){
                alert('Field Name cannot be Empty')
                return
            }
            if(event.target['companyOwner'].value.length < 1){
                alert('Company Owner cannot be Empty')
                return
            }
            if(event.target['pic'].value.length !== 8){
                alert('PIC should be 8 Characters')
                return
            }
            let geoPoints = []
            let geofenceIndex = geofenceData.findIndex(value => value.id === index)
            activeCoordinates.map(data => geoPoints.push([data.lng,data.lat]))
            console.log(geoPoints)
            updateApiData('geofences/',index,{
                name: event.target['name'].value,
                pic: event.target['pic'].value.toUpperCase(),
                companyOwner: event.target['companyOwner'].value,
                points : geoPoints
            },localStorage.getItem('session'))
            .then(data => {
                    alert('Geofence Updated Successfully')
                    geofenceData[geofenceIndex] = data.data.data
                    console.log(data.data.data)
                    setActiveId('')
                    // fetchData()
                }
            )
            .catch(err => console.log(err))
            console.log('save',index)
        }


        return (
            isLoaded
                ?
                <div className='map-container' style={{ position: 'relative' }}>
                    {/* {
                        drawingManagerRef.current
                        &&
                        <div
                            onClick={onDeleteDrawing}
                            title='Delete shape'
                            style={deleteIconStyle}>
                            <DeleteOutlineOutlinedIcon/>
                        </div>
                    } */}
                    <GoogleMap
                        zoom={15}
                        center={center}
                        onLoad={onLoadMap}
                        mapContainerStyle={containerStyle}
                        onTilesLoaded={() => setCenter(null)}
                    >
                        <DrawingManager
                            ref={drawingManagerRef}
                            drawingMode={drawingMode}
                            onLoad={onLoadDrawingManager}
                            onPolygonComplete={onPolygonComplete}
                            onOverlayComplete={onOverlayComplete}
                            options={drawingManagerOptions}
                        />
                        {
                                polygons.map((iterator, index) => (
                                <>
                                <Polygon
                                    key={index}
                                    onLoad={(event) => onLoadPolygon(event, index)}
                                    onMouseDown={() => onClickPolygon(index)}
                                    onMouseUp={() => onEditPolygon(index)}
                                    onDragEnd={() => onEditPolygon(index)}
                                    options={polygonOptions(index)}
                                    paths={iterator}
                                    draggable={activeId === index && true}
                                    editable={activeId === index && true}
                                />
                                </>
                            ))
                            
                        }
                        <Autocomplete
                            onLoad={onLoadAutocomplete}
                            onPlaceChanged={onPlaceChanged}
                        >
                            <input
                                type='text'
                                placeholder='Search Location'
                                style={autocompleteStyle}
                            />
                        </Autocomplete>
                        {
                            drawingMode !== 'polygon' ? 
                            <Button 
                        style={addGeofenceStyle} 
                        variant='contained'
                        onClick={()=> toggleDrawingMode()}>
                            <AddIcon/> Geofence
                        </Button> :
                        <Button 
                        color='error'
                        style={addGeofenceStyle} 
                        variant='contained'

                        onClick={()=> {
                            toggleDrawingMode()
                            if(addGeofenceModal === true){
                            setAddGeofenceModal(false)
                            onDeleteDrawing()
                            }
                            }}>
                            <ClearIcon/> Cancel
                        </Button>
                        }
                        
                        {geofenceDetail.name !== '' ? 
                        <>
                        <Card style={detailsCardStyle}>
                        <div style={{display: activeId === '' ? 'block': 'none'}}>
                            <CardContent style={{height: 'auto'}}>
                                
                                <Typography variant="h6" component="div" style={{wordWrap: 'break-word'}}>
                                {geofenceDetail.name}
                                </Typography>
                                <Typography variant="body2">
                                Field Area:
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {geofenceDetail.area} m2
                                </Typography>
                                <Typography variant="body2">
                                PIC:
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {geofenceDetail.pic}
                                </Typography>
                                <Typography variant="body2">
                                Created By:
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {geofenceDetail.createdby}
                                </Typography>
                                <Typography variant="body2">
                                Company Owner:
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {geofenceDetail.companyOwner}
                                </Typography>
                                <Typography variant="body2">
                                Declaration:
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {geofenceDetail.declarationForm}
                                </Typography>
                            </CardContent>
                            <CardActions>
                            <Button size="small" onClick={() => deleteGeofence(geofenceDetail.id)}><DeleteOutlineOutlinedIcon/></Button>
                                <Button size="small" onClick={() => editGeofence(geofenceDetail.id)}><EditOutlinedIcon/></Button>
                            </CardActions>
                        </div>
                            <form onSubmit={(event) => saveGeofence(geofenceDetail.id,event)} style={{display: activeId !== '' ? 'block': 'none'}}>
                            <CardContent style={{height: 'auto'}}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    variant="standard"
                                    size="small"
                                    value={geofenceTempData.name}
                                    onChange={(event) => setGeofenceTempData(prev => ({...prev, name: event.target.value}))}
                                />
                                <br/><br/>
                                <TextField
                                    label="PIC"
                                    name="pic"
                                    variant="standard"
                                    size="small"
                                    error={picError}
                                    value={geofenceTempData.pic}
                                    onChange={(event) => setGeofenceTempData(prev => ({...prev, pic: event.target.value}))}
                                />
                                <br/><br/>
                                <TextField
                                    label="Company Owner"
                                    name="companyOwner"
                                    variant="standard"
                                    size="small"
                                    value={geofenceTempData.companyOwner}
                                    onChange={(event) => setGeofenceTempData(prev => ({...prev, companyOwner: event.target.value}))}
                                />


                            </CardContent>
                            <CardActions>
                            <Button size="small" color='error' onClick={() => {
                                polygons[activePolygonIndex.current] = tempCoordinates
                                setActiveId('') 
                            }}>Cancel</Button>
                                <Button size="small" type='submit' color='success' > Save</Button>
                            </CardActions>
                                </form>
                        </Card>
                        </>
                        : addGeofenceModal !== false && <Card style={addGeofenceCardStyle}>
                        <Typography variant='h6' sx={{paddingLeft: '15px', width: '150px',marginBottom: '-15px',paddingTop: '10px'}}>Add Geofence</Typography>
                                <form onSubmit={(event) => addGeofence(geofenceDetail.id,event)}>
                            <CardContent style={{height: 'auto'}}>
                                <TextField
                                    label="Name"
                                    name="name"
                                    variant="standard"
                                    size="small"
                                />
                                <br/><br/>
                                <TextField
                                    label="PIC"
                                    inputProps={{ style: { textTransform: "uppercase" },
                                        maxLength: 8 }}
                                    name="pic"
                                    variant="standard"
                                    size="small"
                                    error={picError}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Company Owner"
                                    name="companyOwner"
                                    variant="standard"
                                    size="small"
                                />
                                <br/>


                            </CardContent>
                            <CardActions disableSpacing>
                            <Button size="small" color='error' onClick={() => {
                            setAddGeofenceModal(false)
                            setDrawingMode('')
                            onDeleteDrawing()
                            }}>Cancel</Button>
                                <Button size="small" type='submit' color='success' > Create</Button>
                            </CardActions>
                                </form>
                        </Card>}
                        
                    </GoogleMap>
                </div>

                :
                null
        );
    }

    export default MapComponent; 