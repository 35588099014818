import client from './Client';

export const createApiData = (apiId, data, session) => {
  // client.post(apiId, data, {
  //   headers: {
  //     Authorization: `Bearer ${session}`,
  //   },
  // });
  return client
    .post(apiId, data, {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    })
    .then((data) => {
      return data;
      //   setRows([])
      //   data.data.data.map(data => setRows(prev => prev = ([...prev, createData(data.id,data.companyOwner,data.name,data.createdBy.firstName)])))
    });
};

export const fetchApiData = (apiId, session) => {
  return client
    .get(apiId, {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    })
    .then((data) => {
      return data;
      //   setRows([])
      //   data.data.data.map(data => setRows(prev => prev = ([...prev, createData(data.id,data.companyOwner,data.name,data.createdBy.firstName)])))
    });
};

export const updateApiData = (apiId, id, data, session) => {
  return client
    .patch(apiId + id, data, {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    })
    .then((data) => {
      return data;
    });
};

export const deleteApiData = (apiId, id, session) => {
  return client
    .delete(apiId + id, {
      headers: {
        Authorization: `Bearer ${session}`,
      },
    })
    .then((data) => {
      return data;
    });
};
